import { useContext, useState } from "react"
import { AuthContext } from "../../../context/AuthProvider"
import { Link } from "react-router-dom"
import "./styles.scss"
import { FieldValues, useForm } from "react-hook-form"
import {
  formatCpf,
  formatPhone,
  getDigitsOnly,
  validateEmail,
  validatePhone,
} from "../../../utils/validation"
import ReactInputMask from "react-input-mask"
import { CustomModal } from "k4n-components"
import Header from "../../features/Header"
import services from "../../../services/svcmesh"

const AccountManagement = () => {
  const {
    user,
    refreshUser,
    openResetPassPage,
    emailVerified,
    hasDigitalSignature,
  } = useContext(AuthContext)

  // const [emailModal, setEmailModal] = useState(false)
  const [phoneModal, setPhoneModal] = useState(false)
  const [isloading, setIsloading] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    getValues,
  } = useForm({
    defaultValues: {
      email: user?.emails[0].email,
      phone: formatPhone(user?.phones[0].phone),
    },
  })

  const handleUpdateFields = async (data: FieldValues) => {
    try {
      await services.bankingService.checkIfEmailIsAvailable(data.email)
    } catch (error) {
      setEmailError(true)
    }

    if (user && user.oidc_id) {
      try {
        setIsloading(true)
        data.phone = getDigitsOnly(data.phone)

        const res = await services.authService.updateUserAccount(
          user.oidc_id,
          data,
        )

        if (res.data) setIsloading(false)
        user.emails[0].email = data.email

        // setEmailModal(res.data.email)
        setPhoneModal(res.data.phone)
      } catch (error) {
        console.log(error)
        setIsloading(false)
      }
    }
  }

  const handleValidatePhone = async () => {
    try {
      let code = (
        document.getElementById(`phone-code-input`) as HTMLInputElement
      )?.value
      code = getDigitsOnly(code)

      const { phone } = getValues()

      if (user?.id)
        await services.bankingService.validatePhone(
          user?.id,
          getDigitsOnly(user.phones[0].phone),
          code,
        )
      await services.bankingService.changePhone(getDigitsOnly(phone), user!.cpf)
      setPhoneModal(false)
      refreshUser()
    } catch (error) {
      console.log(error)
      window.alert(error)
    }
  }

  return (
    <div className="bg-gray">
      <Header />
      <div className="manage-container">
        <div className="manage-title">Gerenciar Conta</div>

        <label className="k4n-input-label">Nome Completo</label>
        <input className="k4n-input" type="text" value={user?.nome} disabled />

        <label className="k4n-input-label">CPF</label>
        <input
          className="k4n-input"
          type="text"
          value={formatCpf(user?.cpf)}
          disabled
        />

        <div className="manage-title-signature mt-4">Senha</div>
        <span
          className="yellow-link mg-left-none"
          onClick={() => openResetPassPage()}
        >
          Alterar senha
        </span>
        <div className="yellow-hr"></div>

        <div className="manage-title-signature">Endereços</div>
        <Link style={{ textDecoration: `none` }} to="/change-address">
          <span className="yellow-link mg-left-none">Alterar Endereço</span>
        </Link>
        <div className="yellow-hr"></div>

        <div className="manage-title-signature">Assinatura Digital</div>
        <Link style={{ textDecoration: `none` }} to="/digital-signature">
          {hasDigitalSignature ? (
            <span className="yellow-link mg-left-none">
              Alterar assinatura digital
            </span>
          ) : (
            <span className="yellow-link mg-left-none">
              Criar assinatura digital
            </span>
          )}
        </Link>
        <div className="yellow-hr"></div>

        <form onSubmit={handleSubmit(handleUpdateFields)}>
          <label className="k4n-input-label">E-mail</label>
          {/* {!emailVerified && (
            <small className="mx-3 text-warning">Email não verificado</small>
          )} */}
          <div className="flex-center">
            <input
              {...register(`email`, {
                required: true,
                validate: validateEmail,
              })}
              className={
                errors?.email ? `k4n-input input-invalid` : `k4n-input`
              }
              inputMode="email"
              onInput={(event) => {
                event.currentTarget.value =
                  event.currentTarget.value.toLowerCase()
              }}
              autoCapitalize="off"
              type="email"
              onChange={() => setEmailError(false)}
            />
          </div>
          {errors.email && <div className="input-error">Email inválido</div>}
          {emailError && user?.emails[0].email !== getValues(`email`) && (
            <div className="input-error">Este email já esta sendo usado</div>
          )}

          <label className="k4n-input-label">Telefone</label>
          {user?.phones[0].status !== 1 && (
            <small className="mx-3 text-warning">Número não confirmado</small>
          )}
          <div className="flex-center">
            <ReactInputMask
              mask="(99) 99999-9999"
              defaultValue={formatPhone(user?.phones[0].phone)}
              {...register(`phone`, {
                pattern: /^\(((\d{2})|(\d{3}))\)\s(\d{5}|\d{4})-\d{4}$/,
                required: true,
                validate: validatePhone,
              })}
              className={
                errors?.phone ? `k4n-input input-invalid` : `k4n-input`
              }
            />
          </div>
          {errors.phone && (
            <div className="input-error">Número de celular inválido</div>
          )}

          <div className="flex-center my-4">
            <Link to="/banking" className="yellow-link">
              Cancelar
            </Link>

            <button className="button-primary" type="submit">
              {isloading ? (
                <div className="save-spinner-container">
                  <div className="save-spinner" />
                </div>
              ) : (
                `Salvar`
              )}
            </button>
          </div>
        </form>
      </div>
      <CustomModal
        // isOpen={emailModal}
        onClose={() => {
          // setEmailModal(false)
          if (!phoneModal) {
            refreshUser()
          }
        }}
        isBanking={true}
      >
        <div>
          Seu email precisa ser verificado. Te enviamos um email de verificação
        </div>
        <div className="flex-center mt-3">
          <button
            onClick={() => {
              // setEmailModal(false)
              if (!phoneModal) {
                refreshUser()
              }
            }}
            className="button-primary"
          >
            Ok
          </button>
        </div>
      </CustomModal>

      <CustomModal
        isOpen={/* !emailModal && */ phoneModal}
        onClose={() => {
          setPhoneModal(false)
          refreshUser()
        }}
        isBanking={true}
      >
        <div className="text-center">
          <div>
            Você precisa confirmar que esse número é seu. <br /> Digite abaixo o
            código que te enviamos por SMS
          </div>
          <div className="my-3">
            <ReactInputMask
              id="phone-code-input"
              mask="9 9 9 9 9"
              className="k4n-input"
              style={{ width: `10rem`, textAlign: `center` }}
              alwaysShowMask
            />
          </div>
          <button
            onClick={() => handleValidatePhone()}
            className="button-primary"
          >
            Verificar
          </button>
        </div>
      </CustomModal>
    </div>
  )
}

export default AccountManagement
