import { useContext, useState } from "react"
import "./styles.scss"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import receive from "../../../../../../assets/images/entrada_dinheiro.svg"
import transfer from "../../../../../../assets/images/transfer.svg"
import copy from "../../../../../../assets/images/copy_yellow.svg"
import arrow_right from "../../../../../../assets/images/arrow-right-yellow.svg"
import arrow from "../../../../../../assets/images/arrow_yellow.svg"
import key from "../../../../../../assets/images/key.svg"
import qrcode from "../../../../../../assets/images/others.svg"
import SVGInject from "@iconfu/svg-inject"
import { CustomModal } from "k4n-components"
import { Link, useNavigate } from "react-router-dom"

const PixPanelMobile = ({ handlePageChange, hasDigitalSignature }) => {
  const navigate = useNavigate()
  const [digitalSignatureModal, setDigitalSignatureModal] = useState(false)
  const handlePayment = (newPage: number) => {
    if (hasDigitalSignature) handlePageChange(newPage)
    else setDigitalSignatureModal(true)
  }

  return (
    <div className="pix-panel-mobile">
      <CustomModal
        isOpen={digitalSignatureModal}
        onClose={() => {
          setDigitalSignatureModal(false)
        }}
        isBanking={true}
      >
        <div style={{ width: `120px`, margin: `0 auto` }}>
          <img
            src={key}
            alt="/"
            width="120"
            height="120"
            className="key-vector"
            onLoad={(e) => SVGInject(e.target)}
          />
        </div>
        <h1 className="digital-signature-h1">
          Crie agora sua assinatura digital <br /> e aproveite todos os recursos
          de sua conta!
        </h1>
        <div className="flex-center mt-3">
          <Link style={{ textDecoration: `none` }} to="/digital-signature">
            <button className="home-bold-btn">Criar</button>
          </Link>
        </div>
      </CustomModal>
      <div className="pix-panel-header-mobile">
        <span onClick={() => navigate(`/banking`)}>
          <img
            src={arrow}
            width="27"
            height="15"
            alt="/"
            onLoad={(e) => SVGInject(e.target)}
          />
        </span>
        <h1 className="pix-panel-h1-mobile">Pix</h1>
        <span className="pix-panel-txt-mobile">
          Receba e faça pagamentos sem pagar nada por isso.
        </span>
      </div>
      <div className="pix-panel-content-mobile">
        <div className="pix-panel-cards-mobile">
          <div
            className="pix-panel-card-mobile"
            onClick={() => handlePayment(2)}
          >
            <span className="pix-panel-card-img-mobile">
              <img src={transfer} alt="/" onLoad={(e) => SVGInject(e.target)} />
            </span>
            <h1 className="pix-panel-card-h1-mobile">Pagar</h1>
          </div>
          <div
            className="pix-panel-card-mobile"
            onClick={() => handlePageChange(3)}
          >
            <span className="pix-panel-card-img-mobile">
              <img src={receive} alt="/" onLoad={(e) => SVGInject(e.target)} />
            </span>
            <h1 className="pix-panel-card-h1-mobile">Receber</h1>
          </div>
        </div>
        <div className="pix-panel-cards-mobile mg-top-16">
          <div
            className="pix-panel-card-mobile"
            onClick={() => handlePayment(4)}
          >
            <span className="pix-panel-card-img-mobile">
              <img src={copy} alt="/" onLoad={(e) => SVGInject(e.target)} />
            </span>
            <h1 className="pix-panel-card-h1-mobile">
              Pix Copia
              <br /> e Cola
            </h1>
          </div>
          <div
            className="pix-panel-card-mobile"
            onClick={() => handlePayment(5)}
          >
            <span className="pix-panel-card-img-mobile">
              <img src={qrcode} alt="/" onLoad={(e) => SVGInject(e.target)} />
            </span>
            <h1 className="pix-panel-card-h1-mobile">
              Pagar com
              <br /> QR Code
            </h1>
          </div>
        </div>
        <div className="pix-panel-config-mobile">
          <h1 className="pix-panel-config-h1-mobile">Configurar Pix</h1>
          <div
            className="pix-panel-config-table-mobile"
            onClick={() => handlePageChange(6)}
          >
            <div className="pix-panel-config-table-align-mobile">
              <span className="pix-content-txt">Minhas chaves</span>
              <span className="pix-tabel-txt">
                Gerencie suas chaves para receber e fazer transferências pelo
                Pix.
              </span>
            </div>
            <img
              className="pix-content-img"
              src={arrow_right}
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default PixPanelMobile
