/* eslint-disable indent */
import SVGInject from "@iconfu/svg-inject"
import { AxiosError } from "axios"
import dayjs from "dayjs"
import { useContext, useEffect, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import Arrow from "../../../../../assets/images/arrow.svg"
import policyIcon from "../../../../../assets/images/policy_icon.svg"
import termsIcon from "../../../../../assets/images/terms_icon.svg"
import { AuthContext } from "../../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import { InsuranceContext } from "../../../../../context/InsuranceContext"
import { useResposive } from "../../../../../hooks/useResponsive"
import { CustomModal, FailureModal } from "k4n-components"
import InsurancePlan from "../plano"
import "./styles.scss"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
import Spinner from "../../../../../assets/images/spinner-white.svg"
import services from "../../../../../services/svcmesh"
import { INFORMACOES_BILHETE_BASE64 } from "../../../../../assets/constants"
import GetInstallmentsResponse from "k4n-svcmesh-sdk/dist/typings/get-installments.dto"
import moment from "moment"
import download from "../../../../../assets/images/download.svg"
import { BoletoContext } from "../../../../../context/BoletoContext"
import { click } from "@testing-library/user-event/dist/click"

const Renew = () => {
  const { user } = useContext(AuthContext)
  const [modalPdf, setModalPdf] = useState(false)
  const [pdf, setPdf] = useState(``)
  const { addRenew, activeProposal } = useContext(InsuranceContext)
  const { changeInsuranceStep } = useContext(BankingNavigationContext)
  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [messageError, setMessageError] = useState(``)
  const [pdfFilename, setPdfFilename] = useState(``)
  const { isTabletOrMobile } = useResposive()
  const [openModalPdfLoading, setOpenModalPdfLoading] = useState(false)
  const [loadingBoletoDownload, setLoadingBoletoDownload] = useState(false)
  const [installments, setInstallments] = useState<GetInstallmentsResponse>()
  const [base64PdfModal, setBase64PdfModal] = useState(``)
  const { recipient } = useContext(BoletoContext)
  const [selectedInstallment, setSelectedInstallment] = useState<{
    installment_number: number
    status: string
    type: string
    due_date: Date
    payment_datetime: Date
    base_value: string
    payment_doc_number: string
  }>()

  const getInstallments = async () => {
    const data = await services.bankingService.getInstallments(
      activeProposal.id!,
    )
    setInstallments(data)
  }

  useEffect(() => {
    getInstallments()
  }, [])

  const getBilhete = async (pdfType: string) => {
    setOpenModalPdfLoading(true)
    const pdfDownloadFilename = `Bilhete-${user?.nome?.replace(/ /g, `_`)}.pdf`

    setPdfFilename(pdfDownloadFilename)
    try {
      const res = await services.bankingService.insurancePdfs(
        pdfType,
        Number(activeProposal.id),
        user?.cpf ? user.cpf : ``,
      )
      if (isTabletOrMobile) {
        const link = document.createElement(`a`)
        link.href = res?.data?.base64File
        link.setAttribute(`download`, pdfDownloadFilename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        setPdf(res?.data?.base64File)
        setModalPdf(true)
      }
    } catch (error) {
      const err = error as AxiosError<any>
      setMessageError(err?.response?.data?.message)
      setPdf(``)
    } finally {
      setOpenModalPdfLoading(false)
    }
  }

  const getPdfInformativo = async () => {
    setOpenModalPdfLoading(true)
    const pdfDownloadFilename = `Informativo-${user?.nome?.replace(
      / /g,
      `_`,
    )}.pdf`
    setPdfFilename(pdfDownloadFilename)

    try {
      if (isTabletOrMobile) {
        const link = document.createElement(`a`)
        link.href = INFORMACOES_BILHETE_BASE64
        link.setAttribute(`download`, pdfDownloadFilename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        setPdf(INFORMACOES_BILHETE_BASE64)
        setModalPdf(true)
      }
    } catch (error) {
      setMessageError(`Houve um erro na hora de criar o arquivo...`)
      setPdf(``)
    } finally {
      setOpenModalPdfLoading(false)
    }
  }
  const downloadPdf = async (barCode) => {
    setLoadingBoletoDownload(true)
    const boleto = await services.bankingService.getBoletoPdf(
      barCode,
      recipient,
    )
    if (boleto && boleto.data.boletoPdf) {
      const linkSource = `data:application/pdf;base64,${boleto.data.boletoPdf}`
      const downloadLink = document.createElement(`a`)
      const fileName = `segundaVia.pdf`
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
      setLoadingBoletoDownload(false)
    }
  }

  function GetInstallments(props: any) {
    console.log(installments)
    return (
      <table className="table installments-table ">
        <thead>
          <tr className="installments-th">
            <th scope="col" className="border-radius-table">
              Parcela
            </th>
            <th scope="col" className="centerExclude">
              Status / <br /> Data de pagamento
            </th>
            <th scope="col">Valor</th>
            <th scope="col">Método de pagamento</th>
            <th scope="col">Data de vencimento</th>
            <th scope="col" className="border-radius-table2"></th>
          </tr>
        </thead>
        <tbody>
          {installments?.data.installments.map((installment, i) => (
            <tr key={i} className="installments-tr">
              <th scope="row">{installment.installment_number}</th>
              <td>
                {installment.status === `open` ? (
                  `aberto`
                ) : installment.status === `canceled` ? (
                  `cancelado`
                ) : installment.status === `substituted ` ? (
                  `substituido`
                ) : installment.status === `manual_payment` ? (
                  `pagamento manual`
                ) : (
                  <div>
                    {moment(installment.payment_datetime).format(`DD-MM-YYYY`)}
                  </div>
                )}
              </td>
              <td>{installment.base_value}</td>
              <td>{installment.type}</td>
              <td>{moment(installment.due_date).format(`DD-MM-YYYY`)}</td>
              <td
                onClick={() => {
                  downloadPdf(installment.payment_doc_number)
                }}
                className="table-dots"
              >
                {activeProposal.method_payment === `boleto` &&
                  installment.payment_doc_number && (
                    <div onClick={() => setSelectedInstallment(installment)}>
                      {installment.installment_number ===
                        selectedInstallment?.installment_number &&
                      loadingBoletoDownload ? (
                        <div
                          className="spinner-border spinner-renew-boleto"
                          role="status"
                        />
                      ) : (
                        <img
                          onLoad={(e) => SVGInject(e.target)}
                          id={`dropdownMenuButton` + i}
                          className="img-dots dropdown-toggle"
                          src={download}
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        />
                      )}
                    </div>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  const closeModalPdf = () => {
    setModalPdf(false)
    setPageNumber(1)
    setNumPages(0)
  }

  function onDocumentLoadSuccess(pdfData: any) {
    setNumPages(pdfData?._pdfInfo?.numPages)
    setPageNumber(1)
  }

  function previousPage() {
    setPageNumber(pageNumber - 1)
  }

  function nextPage() {
    setPageNumber(pageNumber + 1)
  }

  const onRenew = async (tipo: number) => {
    if (tipo === 0) {
      addRenew(false)
      changeInsuranceStep(2)
    }
    if (tipo === 1) {
      addRenew(false)
      changeInsuranceStep(1)
    }
  }

  const reissueBoleto = async () => {
    setLoadingBoletoDownload(true)
    try {
      const boleto = await services.bankingService.getReissueBoletoPdf(
        activeProposal.id!,
      )
      console.log(boleto.data.boletoPdf)
      const linkSource = `data:application/pdf;base64,${boleto.data.boletoPdf}`
      const downloadLink = document.createElement(`a`)
      const fileName = `segundaVia.pdf`
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
      setLoadingBoletoDownload(false)
    } catch (error) {
      console.log(error)
    }
  }

  const proposalStatusObj: {
    [key: string]: string
  } = {
    awaiting_registration: `Aguardando registro na seguradora`,
    awaiting_payment: `Aguardando pagamento da mensalidade`,
    up_to_date: `Em dia`,
    overdue: `Pagamento atrasado`,
    suspended: `Suspensa`,
    completed: `Vigência concluída`,
    up_to_date_but_pending_tokio_payment: `Aguardando validação de pagamento pela seguradora`,
    canceled_but_pending_tokio_cancel: `Aguardando cancelamento na seguradora`,
    boleto_refund_but_pending_tokio_cancel: `Aguardando cancelamento na seguradora`,
  }

  return (
    <>
      <div
        className={
          isTabletOrMobile ? `insurance-step-1-mobile` : `insurance-step-1`
        }
      >
        <div
          className={
            isTabletOrMobile
              ? `insurance-content-step-1`
              : `row insurance-content-step-1`
          }
        >
          <div className="insurance-content-width col-6 col-md-6">
            <InsurancePlan />
          </div>
          <div className="insurance-content-margin col-6 col-md-6">
            <div className="Pv-kikkin">
              <div className="insurance-product-status insurance-product-status-div">
                <div className="row">
                  <div className="col-6">
                    <span className="row insurance-status-title">Status:</span>
                    <span className="row insurance-status-value">
                      {proposalStatusObj[String(activeProposal?.status)]}
                    </span>
                  </div>
                  <div className="col-6" style={{ paddingLeft: 20 }}>
                    <span className="row insurance-status-title">
                      Vence em:
                    </span>
                    <span
                      className="row insurance-status-value"
                      style={{ color: `white`, fontWeight: `normal` }}
                    >
                      {dayjs(activeProposal.created_at, `YYYY-MM-DD`)
                        .add(Number(activeProposal?.deadline), `day`)
                        .format(`DD/MM/YYYY`)}
                    </span>
                  </div>
                </div>
                <div className="insurance-links-container">
                  <div
                    onClick={() => getPdfInformativo()}
                    className="insurance-terms-link"
                  >
                    <img
                      src={termsIcon}
                      className="insurance-terms-apolicy-icon"
                      alt=">"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                    <span className="insurance-terms-apolicy-txt">
                      Informativo
                    </span>
                  </div>

                  {activeProposal?.status !== `awaiting_registration` ? (
                    <div
                      onClick={() => getBilhete(`bilhete_seguro`)}
                      className="insurance-policy-link"
                    >
                      <img
                        src={policyIcon}
                        className="insurance-terms-link"
                        alt=">"
                        onLoad={(e) => SVGInject(e.target)}
                      />
                      <span className="insurance-terms-apolicy-txt">
                        Bilhete de Seguro
                      </span>
                    </div>
                  ) : null}
                </div>

                {activeProposal.status === `completed` && (
                  <button
                    className="insurance-renew-btn width-250"
                    onClick={() => onRenew(0)}
                  >
                    <span className="insurance-renew-btn-txt">Renovar</span>
                    <img
                      className="insurance-renew-arrow"
                      src={Arrow}
                      alt=">"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                  </button>
                )}

                {/* {activeProposal.status === `completed` && (
                  <button
                    className="insurance-renew-btn width-250"
                    onClick={() => onRenew(1)}
                  >
                    <span className="insurance-renew-btn-txt">
                      Contratar outro plano
                    </span>
                    <img
                      className="insurance-renew-arrow"
                      src={Arrow}
                      alt=">"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                  </button>
                )} */}
              </div>
              <div className="insurance-terms-policy">
                <CustomModal
                  isOpen={openModalPdfLoading}
                  isBanking
                  loadingModal={true}
                >
                  <div className="container row justify-content-between align-items-center">
                    <div className="pix-modal-sucess-h1 col-6">
                      Carregando documento...
                    </div>
                    <div className="col-6">
                      <img
                        style={{ marginLeft: `100%`, width: 70 }}
                        src={Spinner}
                        alt="loading"
                        onLoad={(e) => SVGInject(e.target)}
                      />
                    </div>
                  </div>
                </CustomModal>
                <CustomModal
                  isOpen={modalPdf}
                  onClose={closeModalPdf}
                  isBanking
                  center={false}
                >
                  <>
                    <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                      <Page
                        width={isTabletOrMobile ? 200 : 500}
                        height={isTabletOrMobile ? 300 : 500}
                        pageNumber={pageNumber}
                      />
                    </Document>
                    <div>
                      <p className="modal-pdf-text">
                        Página {pageNumber || (numPages ? 1 : `--`)} de{` `}
                        {numPages || `--`}
                      </p>
                      <div style={{ display: `inline-flex` }}>
                        <button
                          type="button"
                          className="pdf-modal-previous-page-button"
                          style={{ opacity: pageNumber <= 1 ? 0.5 : 1 }}
                          disabled={pageNumber <= 1}
                          onClick={previousPage}
                        />
                        <button
                          type="button"
                          className="pdf-modal-next-page-button"
                          disabled={pageNumber >= numPages}
                          style={{ opacity: pageNumber >= numPages ? 0.5 : 1 }}
                          onClick={nextPage}
                        />
                      </div>
                    </div>
                    <a
                      className="modal-pdf-href"
                      download={pdfFilename}
                      href={pdf}
                    >
                      Baixar PDF
                    </a>
                    <button
                      type="button"
                      onClick={closeModalPdf}
                      className="btn-modal-pdf"
                    >
                      <span className="txt-modal-pdf-ok">OK</span>
                      <img
                        src={Arrow}
                        onLoad={(e) => SVGInject(e.target)}
                        className="arrow-next"
                        alt=">"
                      />
                    </button>
                  </>
                </CustomModal>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive insurance-installments">
          <GetInstallments></GetInstallments>
        </div>
      </div>
      <FailureModal
        isOpen={!!messageError}
        width={isTabletOrMobile ? `100%` : `937px`}
        height={isTabletOrMobile ? `100%` : `350px`}
        onClose={() => {
          setMessageError(``)
        }}
      >
        <h1 className="pix-modal-sucess-h1">{messageError}</h1>
      </FailureModal>
    </>
  )
}
export default Renew
