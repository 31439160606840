import "../styles.scss"
import { ChangeEvent, useContext, useState, useEffect } from "react"
import { OnboardingContext } from "../../../../context/OnboardingContext"
import { ThemeContext } from "../../../../context/ThemeContext"
import { useForm } from "react-hook-form"

const IdentityLanding = () => {
  const { theme } = useContext(ThemeContext)
  const { goBack, startIdentity, continueIdentity, saveDocumentType } =
    useContext(OnboardingContext)

  const [loading, setLoading] = useState(false)

  const DocumentType = [
    `RG`,
    `CNH`,
    `RNE`,
    `Passaporte`,
    `Carteira de Trabalho`,
    `Reservista`,
  ]

  const { register, watch } = useForm({
    mode: `onChange`,
    reValidateMode: `onChange`,
  })

  // Watch for the selected document type
  const selectedDocumentType = watch(`identificationType`)
  const isDocumentSelected =
    selectedDocumentType && selectedDocumentType !== `0`

  useEffect(() => {
    if (selectedDocumentType) {
      switch (selectedDocumentType) {
        case `RG`:
          saveDocumentType(2)
          break
        case `CNH`:
          saveDocumentType(5)
          break
        case `RNE`:
          saveDocumentType(0)
          break
        case `Passaporte`:
          saveDocumentType(3)
          break
        case `Carteira de Trabalho`:
          saveDocumentType(0)
          break
        case `Reservista`:
          saveDocumentType(0)
          break
        default:
          break
      }
    }
  }, [selectedDocumentType])

  const handleSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    const files = e.target.files
    if (files && files[0]) {
      const p = files[0]
      const res = await toBase64(p)
      continueIdentity(res)
    }
    setLoading(false)
  }

  const toBase64 = async (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })

  return (
    <div className="gray-bg">
      <div
        className="corner-image-bg"
        style={{
          backgroundImage: `url(${theme?.images?.onboarding_background_image_middle_1})`,
        }}
      >
        <div className="dark-overlay">
          <div className="start-text-container h-100 d-flex flex-column flex-justify-content-center">
            <button
              className="onboarding-go-back-white mt-60px position-absolute top-0"
              onClick={goBack}
            />
            <div className="start-text-title mt-md-5 mt-5 mb-3">
              Vamos validar seus dados através de um documento com foto.
            </div>
            <div className="start-text-message mb-4">
              Pode ser RG, CNH, RNE, passaporte, carteira de trabalho ou
              reservista.
            </div>

            <select
              className="onboarding-pj-select onboarding-278-input"
              id="identificationType"
              defaultValue={`0`}
              {...register(`identificationType`, {
                validate: (value) => value !== `0`,
                required: {
                  value: true,
                  message: `Este campo é obrigatório.`,
                },
              })}
              style={{
                color: `var(--k4n-primary-color)`,
                marginBottom: `15px`,
              }}
            >
              <option value="0" disabled className="bc-input-disabled-bg">
                Tipo de documento
              </option>
              {DocumentType.map((doc, i) => (
                <option key={i} value={doc}>
                  {doc}
                </option>
              ))}
            </select>

            <div className="start-text-message mb-4">
              <span
                className={`onboarding-btn-link ${
                  !isDocumentSelected ? `disabled` : ``
                }`}
                onClick={() =>
                  isDocumentSelected &&
                  !loading &&
                  document.getElementById(`file-input`)?.click()
                }
                style={{
                  cursor: isDocumentSelected ? `pointer` : `not-allowed`,
                  color: isDocumentSelected
                    ? `var(--k4n-secondary-color)`
                    : `#ccc`,
                }}
              >
                Clique para enviar
              </span>
              se você já tiver essas imagens.
            </div>

            <input
              onChange={handleSelect}
              id="file-input"
              accept="image/*"
              type="file"
              className="d-none"
            />

            <button
              className="start-button mb-3"
              onClick={() => isDocumentSelected && startIdentity()}
              disabled={!isDocumentSelected}
              style={{
                backgroundColor: isDocumentSelected
                  ? `var(--k4n-secondary-color)`
                  : `#ccc`,
                cursor: isDocumentSelected ? `pointer` : `not-allowed`,
              }}
            >
              Tudo pronto!
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IdentityLanding
