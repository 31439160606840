import SVGInject from "@iconfu/svg-inject"
import { AxiosError } from "axios"
import dayjs from "dayjs"
import { useContext, useState } from "react"
import Arrow from "../../../../../../../assets/images/arrow.svg"
import Copy from "../../../../../../../assets/images/copy_sucess.svg"
import { AuthContext } from "../../../../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../../../../context/BankingNavigationContext"
import { InsuranceContext } from "../../../../../../../context/InsuranceContext"
import { useResposive } from "../../../../../../../hooks/useResponsive"
import services from "../../../../../../../services/svcmesh"
import { FailureModal, SuccessModal, PrimaryButton } from "k4n-components"
import "./styles.scss"

function InsuranceBoletoPayment() {
  const [loading, setLoading] = useState(false)
  const [copiedDigitableLine, setCopiedDigitableLine] = useState(false)
  const [modalPurchase, setModalPurchase] = useState(false)
  const [modalPurchaseError, setModalPurchaseError] = useState(false)
  const [purchaseError, setPurchaseError] = useState(``)
  const [numLinhaDigtvl, setNumLinhaDigtvl] = useState(``)
  const { isTabletOrMobile } = useResposive()
  const {
    paymentDay,
    selectedPlan,
    hasBeneficiary,
    step1Data,
    beneficiaries,
    addSearchedForActiveProposal,
  } = useContext(InsuranceContext)
  const { changeInsuranceStep } = useContext(BankingNavigationContext)
  const { user } = useContext(AuthContext)

  const onCloseSuccessModal = () => {
    addSearchedForActiveProposal(false)
    changeInsuranceStep(1)
  }

  const onSubmit = async () => {
    setLoading(true)

    const dtVencimento =
      dayjs().date() >= paymentDay
        ? dayjs().add(1, `M`).date(paymentDay).toDate()
        : dayjs().date(paymentDay).toDate()

    const boletoSubscriptionObj = {
      pagador: {
        tipoPessoa: 1,
        documentoFederal: user?.cpf,
        nomeRazao: user?.nome,
        nomeFantasia: ``,
        cidade: user?.addresses[0].cidade,
        uf: user?.addresses[0].uf,
        cep: user?.addresses[0].cep,
        logradouro: `${user?.addresses[0].logradouro
          ?.slice(0, 15)
          .replace(`Avenida`, `Av`)
          .replace(`Rua`, `R`)},${user?.addresses[0].numero}${
          user?.addresses[0].complemento
            ? `,${user?.addresses[0].complemento}`
            : ``
        } `,
      },
      dadosBoleto: {
        dtVencimento,
        dtLimPgto: dayjs(dtVencimento).add(1, `d`).toDate(),
        vlrTitulo: parseFloat(selectedPlan.monthly_payment?.toString()!),
        numDocTit: `0`,
        identdNossoNum: `1`,
        codEspTit: 2,
        dtEmissao: dayjs().subtract(3, `h`).toDate(),
        vlrAbatimento: 0,
        numeroDocumento: ``,
      },
      juros: {
        codigo: `5`,
        vlr: 0,
      },
      multa: {
        codigo: `3`,
        vlr: 0,
      },
      desconto: {
        codigo: `0`,
        vlr: 0,
      },
      instrucoesBeneficiario: [``],
      numeroCarteira: 1,
      tipoRegistro: 1,
    }

    console.log({ boletoSubscriptionObj })

    try {
      const res = await services.bankingService.boletoSubscription(
        boletoSubscriptionObj,
      )
      if (res?.data?.sucesso) {
        setNumLinhaDigtvl(res?.data?.numLinhaDigtvl)

        const purchaseObj = {
          method_payment: `boleto`,
          total_installments: selectedPlan?.max_installments,
          installments_value: selectedPlan.monthly_payment,
          first_due_date: dayjs(dtVencimento).format(`YYYY-MM-DD`),
          accepted_lat_long: step1Data.lat + `,` + step1Data.long,
          billing_day: paymentDay,
          codigo_barras_boleto: res?.data?.numCodBarras,
          product: {
            id: selectedPlan?.product?.id,
            deadline: selectedPlan?.product?.deadline,
            kikkin_commission: Number(selectedPlan?.product?.kikkin_commission),
            name: selectedPlan?.product?.name,
          },
          plan: {
            id: selectedPlan?.id,
            max_installments: selectedPlan?.max_installments,
            monthly_payment: parseFloat(
              selectedPlan?.monthly_payment?.toString()!,
            ),
            carencia: selectedPlan?.carencia,
            coverages: selectedPlan!.coverages!.map((c) => ({
              id: c.id,
              description: c.description,
              coverage: c.coverage,
              franchise: c.franchise,
              carencia: c.carencia,
              value: Number(c.value),
              description_note: c.description_note,
              description_note_id: Number(c.description_note_id),
            })),
            ramo: selectedPlan?.ramo,
            net_value: Number(selectedPlan?.net_value),
            iof: Number(selectedPlan?.iof),
          },
          affiliate: step1Data.representativeCode
            ? { id: step1Data?.representativeCode }
            : undefined,
          customer: {
            nome: step1Data.fullName,
            dtNasc: dayjs(step1Data.birthDate).format(`YYYY-MM-DD`),
            sexo: step1Data.gender === 0 ? `F` : `M`,
            estadoCivil: parseInt(step1Data.maritalStatus?.toString()!),
            cpf: step1Data.cpf,
            addresses: [
              {
                cep: user?.addresses[0].cep,
                logradouro: user?.addresses[0].logradouro,
                numero: user?.addresses[0].numero,
                complemento: user?.addresses[0].complemento,
                bairro: user?.addresses[0].bairro,
                cidade: user?.addresses[0].cidade,
                uf: user?.addresses[0].uf,
              },
            ],
            phones: [
              {
                phone: step1Data.phone,
              },
            ],
            emails: [
              {
                email: step1Data.email,
              },
            ],
          },
          ip: (await services.publicIpService.consultPublicIp()).data.ip,
        }
        if (hasBeneficiary) {
          purchaseObj[`beneficiaries`] = beneficiaries.map((b) => ({
            name: b.name,
            percentage: b.pct,
            kinship: b.kinship,
          }))
        }

        console.log({ purchaseObj })

        try {
          const resPurchase = await services.bankingService.purchase(
            purchaseObj,
          )
          if (resPurchase?.data?.message === `success`) {
            setLoading(false)
            setModalPurchase(true)
          }
        } catch (error) {
          const err = error as AxiosError<any>
          setLoading(false)
          setPurchaseError(
            err?.response?.data?.message ??
              `Não foi possivel finalizar a contratação do seu seguro. Tente novamente mais tarde`,
          )
          setModalPurchaseError(true)
        }
      }
    } catch (error: unknown) {
      const err = error as AxiosError<any>
      setLoading(false)
      setPurchaseError(
        err?.response?.data?.message ??
          `Não foi possivel finalizar a contratação do seu seguro. Tente novamente mais tarde`,
      )
      setModalPurchaseError(true)
    }
  }

  const copyDigitableLine = () => {
    navigator.clipboard.writeText(numLinhaDigtvl)
    setCopiedDigitableLine(true)

    setTimeout(function () {
      setCopiedDigitableLine(false)
    }, 3000)
  }

  return (
    <>
      <div className="insurance-step3-boleto">
        <h1 className="insurance-step3-boleto-h1">Instruções:</h1>
        <div className="col No-padding-less-right">
          <div className="row Mg-top-24">
            <div className="col" style={{ display: `inline-flex` }}>
              <span className={`insurance-step3-boleto-span`}>1</span>
              <h1 className="insurance-step3-boleto-txt">
                Imprima o boleto e pague no banco.
              </h1>
            </div>
          </div>
          <div className="row Mg-top-24">
            <div className="col" style={{ display: `inline-flex` }}>
              <span className={`insurance-step3-boleto-span`}>2</span>
              <h1 className="insurance-step3-boleto-txt">
                Ou pague pela internet utilizando a linha digitável do boleto.
              </h1>
            </div>
          </div>
          <div className="row Mg-top-24">
            <div className="col" style={{ display: `inline-flex` }}>
              <span className={`insurance-step3-boleto-span`}>3</span>
              <h1 className="insurance-step3-boleto-txt">
                A compra será concluída após a confirmação do pagamento do
                boleto.
              </h1>
            </div>
          </div>
        </div>
        <div className="insurance-completed">
          <button
            type="submit"
            onClick={() => onSubmit()}
            className="insurance-next-btn"
            disabled={loading}
          >
            {loading ? (
              <div className="spinner-border spinner-pix" role="status"></div>
            ) : (
              <img src={Arrow} className="insurance-next-arrow" alt=">" />
            )}
          </button>
        </div>
      </div>
      <SuccessModal
        isOpen={modalPurchase}
        width={isTabletOrMobile ? `100%` : `937px`}
        height="auto"
        onClose={onCloseSuccessModal}
      >
        <>
          <h1 className="pix-modal-sucess-h1">
            Seguro solicitado com <br /> sucesso!
          </h1>

          {isTabletOrMobile ? (
            ``
          ) : (
            <h1 className="pix-modal-sucess-3p">• • •</h1>
          )}
          <h1 className="pix-modal-sucess-txt">
            Tudo certo! Seu seguro
            <br />
            será efetivado após a confirmação do pagamento.
          </h1>
          <div className="row justify-content-center">
            {numLinhaDigtvl !== `` && (
              <div className="col-12 modal-sucess-purchase-boleto">
                <span className="insurance-txt-code-boleto">
                  Linha digitável: {numLinhaDigtvl}
                </span>
              </div>
            )}
            <div className="col-12 modal-sucess-purchase-boleto">
              <a
                className={`insurance-step4-boleto-href Mg-top-30 Hover-pointer`}
                onClick={copyDigitableLine}
              >
                Copiar linha digitável
              </a>
            </div>
            {copiedDigitableLine && (
              <div className="row insurance-codigo-barras-copiado">
                <img
                  src={Copy}
                  className="insurance-copy-icon"
                  alt=""
                  onLoad={(e) => SVGInject(e.target)}
                />
                <span className={`insurance-copy-text`}>
                  A linha digitável foi copiada com sucesso.
                </span>
              </div>
            )}
          </div>
          <div className="pix-align-center">
            <PrimaryButton
              width={`312px`}
              height={`60px`}
              onClick={onCloseSuccessModal}
            >
              OK!
            </PrimaryButton>
          </div>
        </>
      </SuccessModal>
      <FailureModal
        isOpen={modalPurchaseError}
        width={isTabletOrMobile ? `100%` : `937px`}
        height="auto"
        onClose={() => changeInsuranceStep(1)}
      >
        <h1 className="pix-modal-sucess-h1">Ocorreu um Erro</h1>
        {isTabletOrMobile ? `` : <h1 className="pix-modal-sucess-3p">• • •</h1>}
        <h1 className="pix-modal-sucess-txt">{purchaseError}</h1>
        <div className="pix-align-center">
          <PrimaryButton
            width={`312px`}
            height={`60px`}
            onClick={() => changeInsuranceStep(1)}
          >
            OK!
          </PrimaryButton>
        </div>
      </FailureModal>
    </>
  )
}

export default InsuranceBoletoPayment
