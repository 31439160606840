import { ChangeEvent, useState, useEffect } from "react"
import "./styles.scss"
import galleryIcon from "../../../assets/images/gallery.svg"
import flashIcon from "../../../assets/images/flash.svg"
import SVGInject from "@iconfu/svg-inject"

interface OnboardingCameraProps {
  gallery?: boolean
  confirmText?: string
  photoText?: string
  onConfirm: (photoUrl: string) => void
  loading?: boolean
  error?: string
  clearError?: () => void
  initialPhoto?: string
}

const OnboardingCamera = ({
  onConfirm,
  loading = false,
  gallery = true,
  photoText = ``,
  confirmText = ``,
  error = ``,
  clearError = () => {},
  initialPhoto = ``,
}: OnboardingCameraProps) => {
  const [photoUrl, setPhotoUrl] = useState(initialPhoto)
  const [stream, setStream] = useState<MediaStream>()
  const [canvaSizes, setCanvaSizes] = useState<any>({ height: 100, width: 100 })

  useEffect(() => {
    if (!photoUrl) startCamera()
    return () => stopCamera()
  }, [photoUrl])

  useEffect(() => {
    const track = stream?.getVideoTracks()[0]
    if (track) {
      const { width, height } = track.getSettings()
      const video = document.getElementById(`video-camera`) as HTMLVideoElement
      // Wait for video
      setTimeout(() => {
        if (video?.offsetWidth && video?.offsetHeight && height && width) {
          const swapped =
            height < width && video?.offsetWidth < video?.offsetHeight
          setCanvaSizes({
            width: swapped ? height : width,
            height: swapped ? width : height,
          })
        }
      }, 1000)
    }
  }, [stream])

  const persistFiles = async (files: FileList) => {
    const p = files[0]
    if (p?.type?.includes(`image`)) {
      const res = await toBase64(p)
      setPhotoUrl(res)
    } else {
      console.log(`not a image`)
    }
  }

  const toBase64 = async (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })

  const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files && files[0]) persistFiles(files)
  }

  const startCamera = async () => {
    const str = await navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: [`environment`],
      },
      audio: false,
    })
    setStream(str)
  }

  const stopCamera = () => {
    stream?.getTracks().forEach((track) => {
      stream.removeTrack(track)
      track.stop()
    })
  }

  const turnOnTorch = () => {
    stream
      ?.getTracks()
      .forEach((track) =>
        (track as any).applyConstraints({ advanced: [{ torch: true }] }),
      )
  }

  const handleTake = () => {
    const video = document.getElementById(`video-camera`) as HTMLVideoElement
    const canvas = document.getElementById(`canvas-camera`) as HTMLCanvasElement

    if (canvas) {
      canvas
        .getContext(`2d`)
        ?.drawImage(video, 0, 0, canvas.width, canvas.height)
      const imageUrl = canvas.toDataURL(`image/jpeg`)

      stopCamera()

      setPhotoUrl(imageUrl)
    }
  }

  const handleConfirm = async () => {
    stopCamera()
    if (photoUrl) onConfirm(photoUrl)
  }

  const handleTakeAgain = () => {
    clearError()
    setPhotoUrl(``)
  }

  return photoUrl ? (
    <div className="ob-camera-container">
      <div className="ob-camera-frame">
        <img src={photoUrl} width={`100%`} height={`100%`} alt="selected" />
      </div>
      <div className="mt-4 mx-auto">{confirmText}</div>
      <div className="d-flex justify-content-between mb-3">
        <button onClick={handleTakeAgain} className="onboarding-btn-link">
          Tirar de novo
        </button>
        {error ? (
          <div className="input-error m-0 text-center">{error}</div>
        ) : (
          <button
            className={`onboarding-btn-next` + (loading ? ` loading` : ``)}
            disabled={loading}
            onClick={handleConfirm}
          />
        )}
      </div>
    </div>
  ) : (
    <div
      className="ob-camera-container"
      onDrop={(e) => {
        e.stopPropagation()
        e.preventDefault()
        persistFiles(e.dataTransfer.files)
      }}
      onDragOver={(e) => {
        e.stopPropagation()
        e.preventDefault()
        e.dataTransfer.dropEffect = `copy`
      }}
    >
      <div className="ob-camera-frame">
        {photoText && <div className="ob-camera-text">{photoText}</div>}
        <video
          ref={(vid) => {
            if (vid) vid.srcObject = stream || null
          }}
          id="video-camera"
          width="100%"
          height="100%"
          autoPlay
          playsInline
          controls={false}
        />

        <canvas
          id="canvas-camera"
          style={{ position: `absolute` }}
          width={canvaSizes.width}
          height={canvaSizes.height}
        ></canvas>
      </div>

      <div className="d-flex justify-content-around align-items-center mt-3">
        <button
          onClick={() => document.getElementById(`file-input`)?.click()}
          className="btn-icon"
          style={{ visibility: gallery ? `visible` : `hidden` }}
        >
          <img
            src={galleryIcon}
            height="100%"
            alt="gallery"
            onLoad={(e) => SVGInject(e.target)}
          />
          <input
            onChange={handleSelect}
            id="file-input"
            accept="image/*"
            type="file"
            className="d-none"
          />
        </button>
        <button onClick={handleTake} className="ob-camera-shot-button" />
        <button
          className="btn-icon"
          onClick={turnOnTorch}
          style={{ visibility: `hidden` }}
        >
          <img
            src={flashIcon}
            height="100%"
            alt="flash"
            onLoad={(e) => SVGInject(e.target)}
          />
        </button>
      </div>
    </div>
  )
}

export default OnboardingCamera
