import { useState, FocusEvent } from "react"
import { Controller, useForm } from "react-hook-form"
import "./styles.scss"
import InputMask from "react-input-mask"
import { CurrencyInput } from "k4n-components"
import dayjs from "dayjs"
import dayjsBusinessTime from "dayjs-business-time"

import DatePicker, { registerLocale } from "react-datepicker"
import {
  price_to_number,
  validateCurrency,
} from "../../../../../../utils/validation"
import ptBR from "date-fns/locale/pt-BR"
import { BcStep2 } from "../../../../../../typings/boleto-creation.dto"
import { useContext } from "react"
import { BoletoContext } from "../../../../../../context/BoletoContext"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { useResposive } from "../../../../../../hooks/useResponsive"
import BoletoCreationStep2Mobile from "./mobile"
import { Discount, Fee, Fine } from "k4n-svcmesh-sdk"

registerLocale(`pt-BR`, ptBR)

// Attach dayjs plugin
dayjs.extend(dayjsBusinessTime)

interface InputFocus {
  nominalValue?: boolean
  reduction?: boolean
  dueDate?: boolean
  deadline?: boolean
  fee?: boolean
  fine?: boolean
  discount?: boolean
  feeValue?: boolean
  fineValue?: boolean
  discountDate?: boolean
  discountValue?: boolean
}

export interface Props {
  handleCancel: () => void
}

const BoletoCreationStep2: React.FC<Props> = ({ handleCancel }) => {
  const defaultFocus = {
    nominalValue: false,
    reduction: false,
    dueDate: false,
    deadline: false,
    fee: false,
    fine: false,
    discount: false,
  } as InputFocus

  const [focusList, setFocusList] = useState<InputFocus>(defaultFocus)
  const [dueDate, setDueDate] = useState<any>(null)
  const [deadline, setDeadline] = useState<any>(null)
  const { addBcStep2 } = useContext(BoletoContext)
  const { changeBoletoStep } = useContext(BankingNavigationContext)
  const [fee, setFee] = useState(`5`)
  const [feeValue, setFeeValue] = useState<number>(0)
  const [fine, setFine] = useState(`3`)
  const [fineValue, setFineValue] = useState<number>(0)
  const [discount, setDiscount] = useState(`0`)
  const [nominalValue, setNominalValue] = useState<number>(0)
  const [reductionValue, setReductionValue] = useState<number>(0)
  const { isTabletOrMobile } = useResposive()

  const {
    register,
    handleSubmit,
    control,
    resetField,
    formState: { errors },
  } = useForm()

  const handleFocus = (
    event:
      | FocusEvent<HTMLInputElement>
      | FocusEvent<HTMLSelectElement, Element>,
  ) => {
    switch (event.target.id) {
      case `nominalValue`:
        setFocusList({ nominalValue: true })
        break
      case `reduction`:
        setFocusList({ reduction: true })
        break
      case `dueDate`:
        setFocusList({ dueDate: true })
        break
      case `deadline`:
        setFocusList({ deadline: true })
        break
      case `fee`:
        setFocusList({ fee: true })
        break
      case `fine`:
        setFocusList({ fine: true })
        break
      case `discount`:
        setFocusList({ discount: true })
        break
      case `feeValue`:
        setFocusList({ feeValue: true })
        break
      case `fineValue`:
        setFocusList({ fineValue: true })
        break
      case `discountDate`:
        setFocusList({ discountDate: true })
        break
      case `discountValue`:
        setFocusList({ discountValue: true })
        break
    }
  }

  const handleSelectFocusEnd = (event: FocusEvent<HTMLSelectElement>) => {
    if (parseInt(event.target.value) === 0) {
      setFocusList(defaultFocus)
    }
  }

  const validateDueDate = (date: any) => {
    if (dayjs(date).isAfter(dayjs())) return true
    else return false
  }

  const BiggerThanDueDate = (date: any) => {
    if (dueDate) {
      if (dayjs(dueDate).diff(date, `days`) < 0) return true
      else return false
    } else return false
  }

  const validateDiscountDate = (date: any) => {
    const today = dayjs()

    if (dayjs(today).diff(date, `days`) <= 0) {
      if (dueDate) {
        if (dayjs(dueDate).diff(date, `days`) > 0) return true
        else return false
      }
    } else return false
  }

  const validateDiscountValue = (value: any) => {
    let fixedValue = 0
    const documentTotal = nominalValue - reductionValue

    if (discount === `1` || discount === `3`) {
      fixedValue = price_to_number(value)
      if (fixedValue > 0) {
        if (fixedValue < documentTotal) return true
        else return false
      } else return false
    } else {
      fixedValue = parseFloat(value)
      if (fixedValue > 0) {
        if (fixedValue < 100) return true
        else return false
      } else return false
    }
  }

  const validateReduction = (value: string) => {
    const fixedValue = price_to_number(value)
    if (fixedValue === 0.0) return true
    else {
      if (fixedValue < nominalValue) return true
      else return false
    }
  }

  const validateFeeValue = (value: string) => {
    let fixedValue = 0

    if (fee === `1` || fee === `6`) {
      fixedValue = price_to_number(value)
      if (fixedValue > 0) return true
      else return false
    } else {
      fixedValue = parseFloat(value)
      if (fixedValue > 0) return true
      else return false
    }
  }

  const validateFineValue = (value: string) => {
    let fixedValue = 0

    if (fine === `1`) {
      fixedValue = price_to_number(value)
      if (fixedValue > 0) return true
      else return false
    } else {
      fixedValue = parseFloat(value)
      if (fixedValue > 0) return true
      else return false
    }
  }

  const onDueDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setDueDate(newValue)
  }

  const onDeadlineChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setDeadline(newValue)
  }

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    const name = e.target.name
    const fixedNumber = price_to_number(newValue)

    if (name === `nominalValue`) setNominalValue(fixedNumber)
    else if (name === `feeValue`) setFeeValue(fixedNumber)
    else if (name === `fineValue`) setFineValue(fixedNumber)
    else if (name === `reduction`) setReductionValue(fixedNumber)
  }

  const onValuePctChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    const name = e.target.name
    const fixedNumber = parseFloat(newValue)
    if (name === `nominalValue`) setNominalValue(fixedNumber)
    else if (name === `feeValue`) setFeeValue(fixedNumber)
    else if (name === `fineValue`) setFineValue(fixedNumber)
  }

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const id = event.target.id
    const value = event.target.value
    switch (id) {
      case `fee`:
        resetField(`feeValue`)
        setFee(value)
        break

      case `fine`:
        resetField(`fineValue`)
        setFine(value)
        break

      case `discount`:
        resetField(`discountValue`)
        setDiscount(value)
        break
    }
  }

  const onSubmit = (data: any) => {
    const saveObj = {
      nominalValue: price_to_number(data.nominalValue),
      reduction: price_to_number(data.reduction),
      issueDate: data.issueDate,
      dueDate: data.dueDate,
      deadline: data.deadline,
      numDoc: data.numDoc,
    } as BcStep2

    const feeAndFineDate = dayjs(dueDate).add(1, `day`).toDate()

    let feeFixedValue = 0
    let fineFixedValue = 0
    let discountFixedValue = 0

    if (fee === `1` || fee === `6`)
      feeFixedValue = price_to_number(data.feeValue)
    else feeFixedValue = parseFloat(data.feeValue)

    if (fine === `1`) fineFixedValue = price_to_number(data.fineValue)
    else fineFixedValue = parseFloat(data.fineValue)

    if (discount === `1` || discount === `3`)
      discountFixedValue = price_to_number(data.discountValue)
    else discountFixedValue = parseFloat(data.discountValue)

    saveObj.fee = {
      code: data.fee,
      date: feeAndFineDate,
      value: feeFixedValue,
    } as Fee
    saveObj.fine = {
      code: data.fine,
      date: feeAndFineDate,
      value: fineFixedValue,
    } as Fine
    saveObj.discount = {
      code: data.discount,
      date: data?.discountDate,
      value: discountFixedValue,
    } as Discount

    addBcStep2(saveObj)
    changeBoletoStep(3)
  }

  return (
    <>
      {!isTabletOrMobile ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)} className="bc-step">
            <div className="bc-step-header">
              <div>
                <h1 className="bc-step-title vertical-center">
                  Dados da cobrança e identificação
                </h1>
              </div>
            </div>

            <div className="row bc-step-2-input-row-1 mx-wd-row">
              <div className="col mx-wd-339">
                <label
                  className={`boleto-creation-input-label show-input`}
                  htmlFor="nominalValue"
                >
                  Valor nominal
                </label>
                <label
                  className={`boleto-creation-input-label currency-txt show-input`}
                  htmlFor="nominalValue"
                >
                  R$
                </label>
                <Controller
                  name="nominalValue"
                  control={control}
                  defaultValue="0,00"
                  rules={{
                    required: true,
                    validate: validateCurrency,
                    onChange: onValueChange,
                  }}
                  render={({ field }) => (
                    <CurrencyInput
                      value={field.value}
                      options={{ style: `decimal`, allowNegative: false }}
                      onChangeEvent={(_, maskedValue) => {
                        field.onChange(maskedValue)
                      }}
                      required={true}
                      className="boleto-creation-input pd-currency nominal"
                    />
                  )}
                />

                {errors.nominalValue && (
                  <p className="bc-error-msg">Por favor, verifique o valor.</p>
                )}
              </div>

              <div className="col mx-wd-339">
                <label
                  className={`boleto-creation-input-label show-input`}
                  htmlFor="reduction"
                >
                  Abatimento
                </label>
                <label
                  className={`boleto-creation-input-label currency-txt show-input`}
                  htmlFor="reduction"
                >
                  R$
                </label>
                <Controller
                  name="reduction"
                  control={control}
                  defaultValue="0,00"
                  rules={{
                    required: false,
                    validate: validateReduction,
                    onChange: onValueChange,
                  }}
                  render={({ field }) => (
                    <CurrencyInput
                      value={field.value}
                      options={{ style: `decimal`, allowNegative: false }}
                      onChangeEvent={(_, maskedValue) => {
                        field.onChange(maskedValue)
                      }}
                      className="boleto-creation-input pd-currency "
                    />
                  )}
                />

                {errors.reduction && (
                  <p className="bc-error-msg">Por favor, verifique o valor.</p>
                )}
              </div>

              <div className="col input-doc-mt-24">
                <label
                  className={`boleto-creation-input-label show-input`}
                  htmlFor="reduction"
                >
                  Nº Documento
                </label>

                <input
                  type="text"
                  placeholder="Nº Documento"
                  maxLength={10}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                  className="boleto-creation-input documento"
                  {...register(`numDoc`, {
                    required: true,
                    pattern: /^[0-9]+$/,
                  })}
                />

                {errors.numDoc && (
                  <p className="bc-error-msg">
                    Por favor, preencha com números.
                  </p>
                )}
              </div>
            </div>

            <div className="bc-step-2-header-2">
              <h1 className="bc-step-2-title vertical-center">Datas</h1>
            </div>

            <div className="row bc-step-2-input-row-1 mx-wd-row">
              <div className="col mx-wd-140 bc-step-2-issue-date-col">
                <span className="bc-step-2-issue-date-txt">
                  Data de emissão
                </span>
                <span className="bc-input-value-txt">
                  {dayjs().format(`DD/MM/YYYY`)}
                </span>
              </div>
              <div className="col mx-wd-228">
                <label
                  className={`boleto-creation-input-label show-input`}
                  htmlFor="dueDate"
                >
                  Data do vencimento
                </label>
                <Controller
                  control={control}
                  name="dueDate"
                  rules={{
                    required: true,
                    validate: validateDueDate,
                    onChange: onDueDateChange,
                  }}
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      locale="pt-BR"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Data de vencimento"
                      className="boleto-creation-input bc-step-2-select-due-date"
                      id="dueDate"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={
                        <InputMask mask="99/99/9999" maskPlaceholder={null} />
                      }
                    />
                  )}
                />
                {errors.dueDate && errors.dueDate?.type === `required` && (
                  <p className="bc-error-msg">
                    Por favor, verifique a data informada.
                  </p>
                )}

                {errors.dueDate && (
                  <>
                    {errors.dueDate?.type !== `required` && (
                      <p className="bc-error-msg">
                        Deve ser maior que a data de hoje
                      </p>
                    )}
                  </>
                )}
              </div>
              <div className="col">
                <label
                  className={`boleto-creation-input-label show-input`}
                  htmlFor="deadline"
                >
                  Data limite para pagamento
                </label>
                <Controller
                  control={control}
                  name="deadline"
                  rules={{
                    required: true,
                    validate: BiggerThanDueDate,
                    onChange: onDeadlineChange,
                  }}
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      locale="pt-BR"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Data limite para pagamento"
                      className="boleto-creation-input bc-step-2-select-deadline"
                      id="deadline"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={
                        <InputMask mask="99/99/9999" maskPlaceholder={null} />
                      }
                    />
                  )}
                />
                {errors.deadline && errors.deadline?.type === `required` && (
                  <p className="bc-error-msg">
                    Por favor, verifique a data informada.
                  </p>
                )}

                {errors.deadline && (
                  <>
                    {errors.deadline?.type !== `required` && (
                      <p className="bc-error-msg">
                        Deve ser maior que a data de vencimento
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="bc-step-2-header-2">
              <h1 className="bc-step-2-title vertical-center">Juros e multa</h1>
            </div>

            <div className="row bc-step-2-input-row-1 mx-wd-row">
              <div className="col mx-wd-245">
                <label
                  className={`boleto-creation-input-label show-input`}
                  htmlFor="fee"
                >
                  Juros
                </label>
                <select
                  className="bc-step-2-select bc-input-value-txt pr-36 mb-24"
                  id="fee"
                  onFocus={handleFocus}
                  {...register(`fee`, {
                    required: true,
                    onBlur: handleSelectFocusEnd,
                    onChange: onSelectChange,
                  })}
                >
                  <option value="5" selected>
                    Isento
                  </option>
                  <option value="1">Valor (dias corridos)</option>
                  <option value="3">Percentual ao mês (dias corridos)</option>
                  {/* <option value="6">Valor (dias úteis)</option>
                  <option value="8">Percentual ao mês (dias úteis)</option> */}
                </select>
                {fee !== `5` && (
                  <>
                    {fee !== `3` && fee !== `8` ? (
                      <>
                        <label
                          className={`boleto-creation-input-label show-input`}
                          htmlFor="feeValue"
                        >
                          Valor
                        </label>
                        <label
                          className={`boleto-creation-input-label currency-txt show-input`}
                          htmlFor="feeValue"
                        >
                          R$
                        </label>
                        <Controller
                          name="feeValue"
                          control={control}
                          defaultValue="0,00"
                          rules={{
                            required: true,
                            validate: validateFeeValue,
                            onChange: onValueChange,
                          }}
                          render={({ field }) => (
                            <CurrencyInput
                              value={field.value}
                              options={{
                                style: `decimal`,
                                allowNegative: false,
                              }}
                              onChangeEvent={(_, maskedValue) => {
                                field.onChange(maskedValue)
                              }}
                              required={true}
                              className={
                                errors.feeValue
                                  ? `boleto-creation-input pd-currency bc-step-2-fee-value`
                                  : `boleto-creation-input pd-currency bc-step-2-fee-value mb-24`
                              }
                            />
                          )}
                        />
                      </>
                    ) : (
                      <>
                        <label
                          className={`boleto-creation-input-label show-input`}
                          htmlFor="feeValue"
                        >
                          Valor percentual %
                        </label>
                        <input
                          type="number"
                          step="0.01"
                          maxLength={3}
                          placeholder="0.00"
                          className={
                            errors.feeValue
                              ? `boleto-creation-input pd-currency pl-24 bc-step-2-fee-value`
                              : `boleto-creation-input pl-24 pd-currency bc-step-2-fee-value mb-24`
                          }
                          onInput={(event) => {
                            if (parseFloat(event.currentTarget.value) > 100)
                              event.currentTarget.value =
                                event.currentTarget.value.slice(0, 2)
                            else if (event.currentTarget.value.length > 5)
                              event.currentTarget.value =
                                event.currentTarget.value.slice(0, 5)
                          }}
                          {...register(`feeValue` as const, {
                            required: true,
                            min: 0,
                            max: 100,
                            maxLength: 5,
                            onChange: onValuePctChange,
                            validate: validateFeeValue,
                          })}
                        />
                      </>
                    )}

                    {errors.feeValue && (
                      <p className="bc-error-msg">
                        Por favor, verifique o valor.
                      </p>
                    )}
                  </>
                )}
              </div>
              <div className="col mx-wd-245">
                <label
                  className={`boleto-creation-input-label show-input`}
                  htmlFor="fine"
                >
                  Multa
                </label>
                <select
                  className="bc-step-2-select bc-input-value-txt pr-36 mb-24"
                  id="fine"
                  onFocus={handleFocus}
                  {...register(`fine`, {
                    required: true,
                    onBlur: handleSelectFocusEnd,
                    onChange: onSelectChange,
                  })}
                >
                  <option value="3" selected>
                    Isento
                  </option>
                  <option value="2">Percentual</option>
                  <option value="1">Valor fixo</option>
                </select>

                {fine !== `3` && (
                  <>
                    {fine === `1` ? (
                      <>
                        <label
                          className={`boleto-creation-input-label show-input`}
                          htmlFor="fineValue"
                        >
                          Valor
                        </label>
                        <label
                          className={`boleto-creation-input-label currency-txt show-input`}
                          htmlFor="fineValue"
                        >
                          R$
                        </label>
                        <Controller
                          name="fineValue"
                          control={control}
                          defaultValue="0,00"
                          rules={{
                            required: true,
                            validate: validateFineValue,
                            onChange: onValueChange,
                          }}
                          render={({ field }) => (
                            <CurrencyInput
                              value={field.value}
                              options={{
                                style: `decimal`,
                                allowNegative: false,
                              }}
                              onChangeEvent={(_, maskedValue) => {
                                field.onChange(maskedValue)
                              }}
                              required={true}
                              className={
                                errors.fineValue
                                  ? `boleto-creation-input pd-currency bc-step-2-fee-value`
                                  : `boleto-creation-input pd-currency bc-step-2-fee-value mb-24`
                              }
                            />
                          )}
                        />
                      </>
                    ) : (
                      <>
                        <label
                          className={`boleto-creation-input-label show-input`}
                          htmlFor="fineValue"
                        >
                          Valor percentual %
                        </label>

                        <input
                          type="number"
                          step="0.01"
                          maxLength={3}
                          placeholder="0.00"
                          className={
                            errors.feeValue
                              ? `boleto-creation-input pd-currency pl-24 bc-step-2-fee-value`
                              : `boleto-creation-input pl-24 pd-currency bc-step-2-fee-value mb-24`
                          }
                          onInput={(event) => {
                            if (parseFloat(event.currentTarget.value) > 100)
                              event.currentTarget.value =
                                event.currentTarget.value.slice(0, 2)
                            else if (event.currentTarget.value.length > 5)
                              event.currentTarget.value =
                                event.currentTarget.value.slice(0, 5)
                          }}
                          {...register(`fineValue` as const, {
                            required: true,
                            min: 0,
                            max: 100,
                            maxLength: 5,
                            onChange: onValuePctChange,
                            validate: validateFineValue,
                          })}
                        />
                      </>
                    )}

                    {errors.fineValue && (
                      <p className="bc-error-msg">
                        Por favor, verifique o valor.
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="bc-step-2-header-2">
              <h1 className="bc-step-2-title vertical-center">Desconto</h1>
            </div>

            <div className="row bc-step-2-input-row-1 mx-wd-row">
              <div className="col mx-wd-364">
                <label
                  className={`boleto-creation-input-label show-input`}
                  htmlFor="discount"
                >
                  Desconto
                </label>
                <select
                  className="bc-step-2-select bc-step-2-select-discount bc-input-value-txt mb-24"
                  id="discount"
                  onFocus={handleFocus}
                  {...register(`discount`, {
                    required: true,
                    onBlur: handleSelectFocusEnd,
                    onChange: onSelectChange,
                  })}
                >
                  <option value="0" selected>
                    Isento
                  </option>
                  <option value="1">Valor fixo até a data informada</option>
                  <option value="2">Percentual até a data informada</option>
                  <option value="3">Valor por antecipação dia corrido</option>
                  <option value="5">
                    Percentual por antecipação dia corrido
                  </option>
                </select>

                {discount !== `0` && (
                  <>
                    {discount === `1` || discount === `3` ? (
                      <>
                        <label
                          className={`boleto-creation-input-label show-input`}
                          htmlFor="discountValue"
                        >
                          Valor
                        </label>
                        <label
                          className={`boleto-creation-input-label currency-txt show-input`}
                          htmlFor="discountValue"
                        >
                          R$
                        </label>
                        <Controller
                          name="discountValue"
                          control={control}
                          defaultValue="0,00"
                          rules={{
                            required: true,
                            validate: validateDiscountValue,
                          }}
                          render={({ field }) => (
                            <CurrencyInput
                              value={field.value}
                              options={{
                                style: `decimal`,
                                allowNegative: false,
                              }}
                              onChangeEvent={(_, maskedValue) => {
                                field.onChange(maskedValue)
                              }}
                              required={true}
                              className={
                                errors.discountValue
                                  ? `boleto-creation-input pd-currency bc-step-2-discount-value`
                                  : `boleto-creation-input pd-currency bc-step-2-discount-value mb-24`
                              }
                            />
                          )}
                        />
                      </>
                    ) : (
                      <>
                        <label
                          className={`boleto-creation-input-label show-input`}
                          htmlFor="discountValue"
                        >
                          Valor percentual %
                        </label>
                        <input
                          type="number"
                          step="0.01"
                          maxLength={3}
                          placeholder="0.00"
                          className={
                            errors.discountValue
                              ? `boleto-creation-input pd-currency pl-24 bc-step-2-discount-value`
                              : `boleto-creation-input pl-24 pd-currency bc-step-2-discount-value mb-24`
                          }
                          onInput={(event) => {
                            if (parseFloat(event.currentTarget.value) > 100)
                              event.currentTarget.value =
                                event.currentTarget.value.slice(0, 2)
                            else if (event.currentTarget.value.length > 5)
                              event.currentTarget.value =
                                event.currentTarget.value.slice(0, 5)
                          }}
                          {...register(`discountValue` as const, {
                            required: true,
                            min: 0,
                            max: 100,
                            maxLength: 5,
                            validate: validateDiscountValue,
                          })}
                        />
                      </>
                    )}

                    {errors.discountValue && (
                      <p className="bc-error-msg">
                        Por favor, verifique o valor.
                      </p>
                    )}

                    <label
                      className={`boleto-creation-input-label show-input`}
                      htmlFor="discountDate"
                    >
                      Data final do desconto
                    </label>
                    <Controller
                      control={control}
                      name="discountDate"
                      rules={{ required: true, validate: validateDiscountDate }}
                      render={({ field }) => (
                        <DatePicker
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          locale="pt-BR"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Data"
                          className="boleto-creation-input bc-step-2-discount-date"
                          id="discountDate"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          customInput={
                            <InputMask
                              mask="99/99/9999"
                              maskPlaceholder={null}
                            />
                          }
                        />
                      )}
                    />

                    {errors.discountDate && (
                      <>
                        {errors.discountDate?.type === `required` && (
                          <>
                            <p className="bc-error-msg">
                              Por favor, verifique a data informada.
                            </p>
                          </>
                        )}
                      </>
                    )}

                    {errors.discountDate && (
                      <>
                        {errors.discountDate?.type !== `required` && (
                          <p className="bc-error-msg">
                            Deve ser menor que a data de vencimento e maior que
                            a data de hoje.
                          </p>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="bc-row-btns">
              <button className="bc-btn-transparent" onClick={handleCancel}>
                Cancelar
              </button>
              <button type="submit" className="bc-btn-yellow">
                Próximo
              </button>
            </div>
          </form>
        </>
      ) : (
        <>
          <BoletoCreationStep2Mobile
            onSubmit={onSubmit}
            validateCurrency={validateCurrency}
            onValueChange={onValueChange}
            validateReduction={validateReduction}
            validateDueDate={validateDueDate}
            onDueDateChange={onDueDateChange}
            BiggerThanDueDate={BiggerThanDueDate}
            onDeadlineChange={onDeadlineChange}
            handleFocus={handleFocus}
            handleSelectFocusEnd={handleSelectFocusEnd}
            onSelectChange={onSelectChange}
            fee={fee}
            validateFeeValue={validateFeeValue}
            onValuePctChange={onValuePctChange}
            fine={fine}
            validateFineValue={validateFineValue}
            discount={discount}
            validateDiscountValue={validateDiscountValue}
            validateDiscountDate={validateDiscountDate}
            handleCancel={handleCancel}
          />
        </>
      )}
    </>
  )
}
export default BoletoCreationStep2
