/* eslint-disable indent */
import ptBR from "date-fns/locale/pt-BR"
import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
import isToday from "dayjs/plugin/isToday"
import { useContext, useState, useRef, useEffect } from "react"
import DatePicker, { CalendarContainer, registerLocale } from "react-datepicker"
import InputMask from "react-input-mask"
import arrowDown from "../../../../../assets/images/arrow_yellow_down.svg"
import calendar from "../../../../../assets/images/calendar-home.svg"
import clearX from "../../../../../assets/images/clear_x.svg"
import dots from "../../../../../assets/images/dots.svg"
import filter from "../../../../../assets/images/filter.svg"
import spinner from "../../../../../assets/images/spinner.svg"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import {
  Pagination,
  SuccessModal,
  ErrorModal,
  PrimaryButton,
} from "k4n-components"
import BoletoCreation from "../new"
import "./styles.scss"
import { AuthContext } from "../../../../../context/AuthProvider"
import { BoletoContext } from "../../../../../context/BoletoContext"
import SVGInject from "@iconfu/svg-inject"
import ArrowLeft from "../../../../../assets/images/yellow-arrow-left.svg"
import { codBarras2LinhaDigitavel } from "../../../../../utils/utils"
import { formattedCurrency } from "../../../../../services/banking"
import services from "../../../../../services/svcmesh"

dayjs.extend(isBetween)
dayjs.extend(isToday)

registerLocale(`pt-BR`, ptBR)

const BoletoManager = () => {
  const { changeBoletoStep } = useContext(BankingNavigationContext)
  const {
    addBcStep1,
    addBcStep2,
    recipient,
    addSearchedFavorites,
    loadingData,
    dataArray,
    statusFilter,
    loadingDropdown,
    dateFilterError,
    boletoStatusSum,
    page,
    totalPages,
    showModal,
    dropdownError,
    modalType,
    modalErrorMessage,
    showErrorModal,
    addShowErrorModal,
    addModalType,
    addShowModal,
    handlePages,
    filterByStatus,
    applyNameFilter,
    applyDateFilter,
    applyFilter,
    clearFilter,
    cancel,
    sendMail,
  } = useContext(BoletoContext)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [openDropdown, setOpenDropdown] = useState(false)
  const [showDropdown, setShowDropdown] = useState<string>(``)
  const [showCollapse, setShowCollapse] = useState<string>(``)
  const [newBoleto, setNewBoleto] = useState(false)
  const [base64Pdf, setBase64Pdf] = useState(``)
  const [base64PdfModal, setBase64PdfModal] = useState(``)
  const [pdfModalLoading, setPdfModalLoading] = useState(false)
  const [pdfModalName, setPdfModalName] = useState(``)
  const [pdfModalError, setPdfModalError] = useState(false)
  const [pdfLoading, setPdfLoading] = useState(false)
  const [showDownloadPdf, setShowDownloadPdf] = useState(false)
  const [loadingSendingMail, setLoadingSendingMail] = useState(false)
  const [sendEmailOption, setSendEmailOption] = useState(false)
  const [recipientEmail, setRecipientEmail] = useState(``)
  const { user } = useContext(AuthContext)
  const [dataLimite, setDataLimite] = useState(``)
  const [loadDataLimite, setloadDataLimite] = useState(false)
  const downloadRef = useRef<HTMLAnchorElement>(null)

  useEffect(() => {}, [loadDataLimite])

  const handleCancel = () => {
    addBcStep1({})
    addBcStep2({})
    changeBoletoStep(1)
    setNewBoleto(false)
    // changeBoletoStep(1)
  }

  const handleSuccess = async (barCode: string) => {
    addBcStep1({})
    addBcStep2({})
    changeBoletoStep(1)
    setPdfModalLoading(true)
    setNewBoleto(false)
    addShowModal(true)
    addModalType(1)
    addSearchedFavorites(false)
    try {
      const pdf = await services.bankingService.getBoletoPdf(barCode, recipient)
      if (pdf && pdf.data.boletoPdf) {
        setBase64PdfModal(`data:application/pdf;base64,` + pdf.data.boletoPdf)
        setPdfModalLoading(false)
        setPdfModalName(barCode)
      }
    } catch (error) {
      setPdfModalError(true)
    }
  }

  const toggleRowDropdown = async (id: string, status?: string) => {
    setPdfLoading(true)
    if (id && status) {
      if (showDropdown === id) setShowDropdown(``)
      else setShowDropdown(id)
      if (
        status === `canceled` ||
        status === `expired` ||
        status === `pendingCancellation`
      ) {
        setShowDownloadPdf(false)
      } else {
        setShowDownloadPdf(true)
        const pdf = await services.bankingService.getBoletoPdf(id, recipient)
        if (pdf && pdf.data.boletoPdf) {
          setBase64Pdf(`data:application/pdf;base64,` + pdf.data.boletoPdf)
          setPdfLoading(false)
        } else setPdfLoading(false)
      }
    }
  }

  const toggleRowCollapse = async (id: string) => {
    setPdfLoading(true)
    if (showCollapse === id) setShowCollapse(``)
    else {
      setShowCollapse(id)

      setloadDataLimite(true)
      const infoBoleto = await services.bankingService.getBoletoInfo({
        linhaDigitavel: codBarras2LinhaDigitavel(id, false),
      })
      setDataLimite(infoBoleto.dadosTitulo.dtLimPgtoTit)
      setloadDataLimite(false)
      const pdf = await services.bankingService.getBoletoPdf(id, recipient)
      if (pdf && pdf.data.boletoPdf) {
        setBase64Pdf(`data:application/pdf;base64,` + pdf.data.boletoPdf)
        setPdfLoading(false)
      }
    }
  }

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown)
  }

  function EmptySpaces() {
    return (
      <>
        <tr>
          <td>
            <div className="table-boleto-id">
              <div className="emptySpace"></div>
            </div>
          </td>
          <td>
            <div className="table-boleto-id">
              <div className="emptySpace"></div>
            </div>
          </td>
          <td>
            <div className="table-boleto-id">
              <div className="emptySpace"></div>
            </div>
          </td>
          <td>
            <div className="table-boleto-id">
              <div className="emptySpace"></div>
            </div>
          </td>
          <td>
            <div className="table-boleto-id">
              <div className="emptySpace"></div>
            </div>
          </td>
        </tr>
      </>
    )
  }

  const handlePdfDownload = () => {
    if (downloadRef && downloadRef.current) downloadRef.current.click()
  }

  const boletoDatePickerContainer = ({ className, children }) => {
    return (
      <div style={{ background: `transparent`, position: `absolute` }}>
        <CalendarContainer className={className}>
          <div
            style={{
              position: `relative`,
            }}
          >
            {children}
          </div>
        </CalendarContainer>
      </div>
    )
  }

  function DownloadPdf(props) {
    const status = props.row.status
    const row = props.row
    const type = props.type
    if (
      status !== `canceled` &&
      status !== `expired` &&
      status !== `pendingCancellation` &&
      status !== `paid`
    ) {
      if (type === `details`) {
        return (
          <div className="col col-flex">
            <a
              href={base64Pdf}
              download={`${row.codigoBarras}.pdf`}
              style={{
                pointerEvents: pdfLoading ? `none` : `all`,
              }}
              className="btn-collapse-transparent"
            >
              {pdfLoading ? (
                <div
                  style={{
                    display: `flex`,
                  }}
                >
                  {`Baixar PDF`}
                  <div
                    className="boleto-row-download-spinner"
                    role="status"
                  ></div>
                </div>
              ) : (
                `Baixar PDF`
              )}
            </a>
          </div>
        )
      } else if (type === `dropdown`)
        return (
          <li
            onClick={handlePdfDownload}
            className="list-group-item li-table-popup"
          >
            {pdfLoading ? (
              <>
                Baixar PDF
                <div className="dropdown-download-spinner" role="status"></div>
              </>
            ) : (
              `Baixar PDF`
            )}
            <a
              href={base64Pdf}
              download={`${row.codigoBarras}.pdf`}
              style={{
                pointerEvents: pdfLoading ? `none` : `all`,
              }}
              ref={downloadRef}
              className="dropdown-download-a"
            ></a>
          </li>
        )
      else return <></>
    } else return <></>
  }

  function CancelBoletoDropdown(props) {
    const row = props.row
    const status = props.row.status
    if (
      status !== `canceled` &&
      status !== `expired` &&
      status !== `pendingCancellation` &&
      status !== `paid`
    ) {
      return (
        <li
          onClick={() => cancel(row.codigoBarras || ``)}
          className="list-group-item li-table-popup"
        >
          Cancelar
        </li>
      )
    } else return <></>
  }

  function handleClearFilter() {
    clearFilter()
  }

  function handleApplyFilter() {
    applyFilter()
    setOpenDropdown(false)
  }

  return (
    <>
      {newBoleto ? (
        <BoletoCreation
          handleSuccess={handleSuccess}
          handleCancel={handleCancel}
        />
      ) : (
        <>
          <body className="notranslate boleto-body-transparent">
            <div className="boleto-manager-container notranslate">
              <div className="boleto-manager">
                <h1 className="boleto-manager-title vertical-center">
                  Gerenciar Boletos
                </h1>
                <input
                  type="text"
                  placeholder="Buscar"
                  onChange={applyNameFilter}
                  className="manager-input-search vertical-center"
                />
              </div>
              <div className="div-new-boleto">
                <button
                  onClick={() => setNewBoleto(true)}
                  className="btn-new-boleto vertical-center"
                >
                  Novo boleto
                </button>
              </div>
            </div>
            <div
              className={
                totalPages > 1
                  ? `boleto-manager-content`
                  : `boleto-manager-content pb-20`
              }
            >
              {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  FILTERS  >>>>>>>>>>>>>>>>>>>>>>>>>>>>. */}
              <div className="row manager-info">
                <div className="col no-padding mx-width-290">
                  <div className="manager-date">
                    <img src={calendar} onLoad={(e) => SVGInject(e.target)} />
                    {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  DATE FILTER  >>>>>>>>>>>>>>>>>>>>>>>>>>>>. */}
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      locale="pt-BR"
                      onChange={(update: any) => {
                        setDateRange(update)
                        applyDateFilter(update)
                      }}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      required={true}
                      placeholderText="Filtre por data de emissão"
                      customInput={
                        <InputMask
                          mask="99/99/9999 - 99/99/9999"
                          maskPlaceholder={null}
                          className="manager-input-date"
                        />
                      }
                      calendarContainer={boletoDatePickerContainer}
                    />
                  </div>
                  {dateFilterError && (
                    <p className="bc-error-msg bm-date-error">
                      O período não pode ser maior que 31 dias.
                    </p>
                  )}
                </div>
                <div className="col max-width-fit no-padding">
                  <div className="manager-boleto-info">
                    <span className="boleto-info-title ">
                      Aguardando pagamento
                    </span>
                    <span className="boleto-info-value">
                      {formattedCurrency(Number(boletoStatusSum.pending))}
                    </span>
                  </div>
                </div>
                <div className="col max-width-fit no-padding">
                  <div className="manager-boleto-info">
                    <span className="boleto-info-title ">Pagos</span>
                    <span className="boleto-info-value">
                      {formattedCurrency(Number(boletoStatusSum.paid))}
                    </span>
                  </div>
                </div>
                <div className="col max-width-fit no-padding">
                  <div className="manager-boleto-info">
                    <span className="boleto-info-title ">Vencidos</span>
                    <span className="boleto-info-value">
                      {formattedCurrency(Number(boletoStatusSum.overdue))}
                    </span>
                  </div>
                </div>
                <div className="col max-width-fit no-padding">
                  <div className="manager-boleto-info">
                    <span className="boleto-info-title ">Cancelados</span>
                    <span className="boleto-info-value">
                      {formattedCurrency(Number(boletoStatusSum.canceled))}
                    </span>
                  </div>
                </div>
                <div className="col no-padding min-wd-85">
                  {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  STATUS FILTER  >>>>>>>>>>>>>>>>>>>>>>>>>>>>. */}
                  <div className="dropdown">
                    <div className="dropdown-height" onClick={toggleDropdown}>
                      <img
                        id="dropdownMenuButton"
                        src={filter}
                        className="manager-filter-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onLoad={(e) => SVGInject(e.target)}
                      />
                    </div>
                    <div
                      className={
                        openDropdown
                          ? `dropdown-menu show manager-filter-popup`
                          : `dropdown-menu manager-filter-popup`
                      }
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div className="boleto-filter-header">
                        <div className="wd-50-left">
                          <span className="boleto-filter-title">Filtros</span>
                        </div>
                        <div className="wd-50-right">
                          <div onClick={() => setOpenDropdown(false)}>
                            <img
                              className="boleto-filter-close"
                              src={clearX}
                              onLoad={(e) => SVGInject(e.target)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="boleto-filter-body">
                        <div className="boleto-filter-buttons">
                          <button
                            onClick={() => filterByStatus(`paid`)}
                            className={
                              !statusFilter.includes(`paid`)
                                ? `boleto-filter-btn`
                                : `boleto-filter-btn boleto-filter-btn-active`
                            }
                          >
                            Pago
                          </button>

                          <button
                            onClick={() => filterByStatus(`expired`)}
                            className={
                              !statusFilter.includes(`expired`)
                                ? `boleto-filter-btn ml-16`
                                : `boleto-filter-btn boleto-filter-btn-active ml-16`
                            }
                          >
                            Expirado
                          </button>
                          <button
                            onClick={() => filterByStatus(`overdue`)}
                            className={
                              !statusFilter.includes(`overdue`)
                                ? `boleto-filter-btn ml-8`
                                : `boleto-filter-btn boleto-filter-btn-active ml-8`
                            }
                          >
                            Vencido
                          </button>
                        </div>
                        <div className="boleto-filter-buttons mt-11">
                          <button
                            onClick={() => filterByStatus(`canceled`)}
                            className={
                              !statusFilter.includes(`canceled`)
                                ? `boleto-filter-btn`
                                : `boleto-filter-btn boleto-filter-btn-active`
                            }
                          >
                            Cancelado
                          </button>
                          <button
                            onClick={() => filterByStatus(`pending`)}
                            className={
                              !statusFilter.includes(`pending`)
                                ? `boleto-filter-btn ml-16`
                                : `boleto-filter-btn boleto-filter-btn-active ml-16`
                            }
                          >
                            Aguardando pagamento
                          </button>
                        </div>

                        <div className="boleto-filter-buttons mt-11">
                          <button
                            onClick={() =>
                              filterByStatus(`pendingCancellation`)
                            }
                            className={
                              !statusFilter.includes(`pendingCancellation`)
                                ? `boleto-filter-btn`
                                : `boleto-filter-btn boleto-filter-btn-active`
                            }
                          >
                            Pendente Cancelamento
                          </button>
                        </div>

                        <div className="boleto-filter-actions">
                          <span
                            className="boleto-filter-clear"
                            onClick={() => handleClearFilter()}
                          >
                            Limpar
                          </span>
                          <button
                            className="boleto-filter-apply"
                            onClick={() => handleApplyFilter()}
                          >
                            Aplicar Filtros
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="boleto-manager-table">
                {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  BOLETO TABLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>. */}
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th className="table-column-title" scope="col">
                        Data Emissão
                      </th>
                      <th className="table-column-title" scope="col">
                        Pagador
                      </th>
                      <th className="table-column-title" scope="col">
                        Identificador
                      </th>
                      <th className="table-column-title" scope="col">
                        Vencimento
                      </th>
                      <th className="table-column-title" scope="col">
                        Valor
                      </th>
                      <th className="table-column-title" scope="col">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loadingData && <EmptySpaces />}
                    {!loadingData && (
                      <>
                        {dataArray && dataArray.length > 0 && (
                          <>
                            {dataArray.map((row, i) => (
                              <>
                                <tr key={i}>
                                  <td className="table-cell-value mx-wd-145">
                                    <div className="table-cell-center">
                                      <div
                                        onClick={() =>
                                          toggleRowCollapse(row.codigoBarras)
                                        }
                                        style={{ float: `left` }}
                                      >
                                        <img
                                          onLoad={(e) => SVGInject(e.target)}
                                          className="table-arrow-down"
                                          src={arrowDown}
                                          data-bs-toggle="collapse"
                                          data-bs-target={
                                            `#collapseExample` + i
                                          }
                                          aria-expanded="false"
                                          aria-controls={`collapseExample` + i}
                                        />
                                      </div>
                                      <div className="ml-24">
                                        {dayjs(row.dataRegistro).isToday() && (
                                          <div className="tag-new">
                                            <span className="tag-new-span">
                                              Novo!
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                      <div className="ml-24 txt-overflow">
                                        {dayjs(row.dataRegistro).format(
                                          `DD/MM/YYYY`,
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="mx-wd-145">
                                    <div className="table-cell-center">
                                      <div className="ml-24 txt-overflow">
                                        <span className="row-txt-bold">
                                          {row.nom_RzSocPagdr}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="table-boleto-id">
                                      <span className="row-span-small-txt">
                                        Nosso número
                                      </span>
                                      <span className="span-our-number-value">
                                        {row.identdNossoNum}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="table-cell-value">
                                    {dayjs(row.dtVencimento).format(
                                      `DD/MM/YYYY`,
                                    )}
                                  </td>
                                  <td className="table-cell-value">
                                    {formattedCurrency(row.vlrBoleto)}
                                  </td>
                                  <td>
                                    <div style={{ position: `relative` }}>
                                      {row.status === `paid` && (
                                        <div className="table-status-paid table-status">
                                          <span className="table-status-span">
                                            Pago
                                          </span>
                                        </div>
                                      )}
                                      {row.status === `pending` && (
                                        <div className="table-status-pending table-status">
                                          <span className="table-status-span">
                                            Aguardando pagamento
                                          </span>
                                        </div>
                                      )}
                                      {row.status === `pendingCancellation` && (
                                        <div className="table-status-pending-cancellation table-status">
                                          <span className="table-status-span">
                                            Cancelando
                                          </span>
                                        </div>
                                      )}
                                      {row.status === `canceled` && (
                                        <div className="table-status-canceled table-status">
                                          <span className="table-status-span">
                                            Cancelado
                                          </span>
                                        </div>
                                      )}
                                      {row.status === `overdue` && (
                                        <div className="table-status-overdue table-status">
                                          <span className="table-status-span">
                                            Vencido
                                          </span>
                                        </div>
                                      )}
                                      {row.status === `expired` && (
                                        <div className="table-status-expired table-status">
                                          <span className="table-status-span">
                                            Expirado
                                          </span>
                                        </div>
                                      )}
                                      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< DROPDOWN TABLE ITEM >>>>>>>>>>>>>>>>>>>>>>>>>>>>. */}
                                      {row.status !== `canceled` &&
                                        row.status !== `pendingCancellation` &&
                                        row.status !== `paid` &&
                                        row.status !== `expired` && (
                                          <>
                                            <div className="dropdown">
                                              <div
                                                onClick={() => {
                                                  toggleRowDropdown(
                                                    row.codigoBarras,
                                                    row.status,
                                                  )
                                                  setSendEmailOption(false)
                                                }}
                                                className="div-dots"
                                              >
                                                <img
                                                  onLoad={(e) =>
                                                    SVGInject(e.target)
                                                  }
                                                  id={`dropdownMenuButton` + i}
                                                  className="img-dots dropdown-toggle"
                                                  src={dots}
                                                  data-bs-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                />
                                              </div>
                                              <div
                                                className={
                                                  showDropdown ===
                                                  row.codigoBarras
                                                    ? `dropdown-menu show manager-table-popup wd-225`
                                                    : `dropdown-menu manager-table-popup wd-225`
                                                }
                                                aria-labelledby={
                                                  `dropdownMenuButton` + i
                                                }
                                              >
                                                <button
                                                  className="boleto-row-close"
                                                  onClick={() => {
                                                    setShowDropdown(``)
                                                  }}
                                                >
                                                  <img
                                                    src={clearX}
                                                    alt=""
                                                    onLoad={(e) =>
                                                      SVGInject(e.target)
                                                    }
                                                  />
                                                </button>

                                                <div className="boleto-dots-drop">
                                                  {loadingDropdown ? (
                                                    <>
                                                      <div className="mb-dropdown-spinner-div">
                                                        <img
                                                          width={50}
                                                          height={50}
                                                          src={spinner}
                                                          alt="loading"
                                                          onLoad={(e) =>
                                                            SVGInject(e.target)
                                                          }
                                                        />
                                                      </div>
                                                      {loadingSendingMail && (
                                                        <div className="email-txt-div">
                                                          <span>
                                                            Enviando para{` `}
                                                            {recipientEmail}
                                                          </span>
                                                        </div>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {dropdownError ? (
                                                        <p className="mb-dropdown-error-msg">
                                                          Houve um erro ao
                                                          realizar essa ação,
                                                          tente novamente mais
                                                          tarde.
                                                        </p>
                                                      ) : (
                                                        <>
                                                          {!sendEmailOption ? (
                                                            <ul className="list-group">
                                                              <CancelBoletoDropdown
                                                                row={row}
                                                              />
                                                              <li
                                                                onClick={() =>
                                                                  setSendEmailOption(
                                                                    !sendEmailOption,
                                                                  )
                                                                }
                                                                className="list-group-item li-table-popup"
                                                              >
                                                                Enviar por email
                                                              </li>
                                                              {sendEmailOption && (
                                                                <input placeholder="Digite o email de destino"></input>
                                                              )}
                                                              {showDownloadPdf && (
                                                                <DownloadPdf
                                                                  row={row}
                                                                  type={`dropdown`}
                                                                />
                                                              )}
                                                            </ul>
                                                          ) : (
                                                            <>
                                                              <img
                                                                src={ArrowLeft}
                                                                alt=""
                                                                style={{
                                                                  cursor: `pointer`,
                                                                }}
                                                                onClick={() => {
                                                                  setSendEmailOption(
                                                                    false,
                                                                  )
                                                                }}
                                                              />
                                                              <div className="mail-dropdown-div">
                                                                <input
                                                                  placeholder="Digite o email de destino"
                                                                  className="mail-dropdown-input"
                                                                  onChange={(
                                                                    e: any,
                                                                  ) => {
                                                                    setRecipientEmail(
                                                                      e.target
                                                                        .value,
                                                                    )
                                                                  }}
                                                                ></input>
                                                                <PrimaryButton
                                                                  width={`100%`}
                                                                  height={`40px`}
                                                                  onClick={() =>
                                                                    sendMail(
                                                                      recipientEmail,
                                                                      row.codigoBarras ||
                                                                        ``,
                                                                    )
                                                                  }
                                                                >
                                                                  Enviar
                                                                </PrimaryButton>
                                                              </div>
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                    </div>
                                  </td>
                                </tr>
                                {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ROW DETAILS >>>>>>>>>>>>>>>>>>>>>>>>>>>>. */}
                                <tr>
                                  <td colSpan={12} className="hidden-row">
                                    <div
                                      className={
                                        showCollapse === row.codigoBarras
                                          ? `collapse row-info show`
                                          : `collapse`
                                      }
                                      id={`collapseExample` + i}
                                    >
                                      <div className="row">
                                        <div className="col">
                                          <div className="collapse-payer-data height-fit pl-30">
                                            <span className="row-txt-bold">
                                              Dados do pagador
                                            </span>
                                            <span className="row-span-small-txt mt-16">
                                              Nome/Razão social
                                            </span>
                                            <span className="table-txt">
                                              {row.nom_RzSocPagdr}
                                            </span>

                                            <span className="row-span-small-txt mt-8">
                                              CPF/CNPJ
                                            </span>
                                            <span className="table-txt">
                                              {row.cnpJ_CPFPagdr}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col">
                                          <span className="row-txt-bold txt-align-top">
                                            Dados da cobrança
                                          </span>

                                          <div className="collapse-payer-data height-fit">
                                            <div className="row row-mx-50">
                                              <div className="col-6">
                                                <span className="row-span-small-txt mt-16">
                                                  Emitido via
                                                </span>
                                                <br />
                                                <span className="table-txt">
                                                  IBPJ
                                                </span>
                                              </div>
                                              <div className="col-6"></div>
                                            </div>
                                            <div className="row row-mx-50">
                                              <div className="col-6">
                                                <span className="row-span-small-txt mt-16">
                                                  Nosso número
                                                </span>
                                                <br />
                                                <span className="table-txt">
                                                  {row.identdNossoNum}
                                                </span>
                                              </div>
                                              <div className="col-6"></div>
                                            </div>
                                            <div className="row row-mx-50">
                                              <div className="col-6">
                                                <span className="row-span-small-txt mt-16">
                                                  Data de vencimento
                                                </span>
                                                <br />
                                                <span className="table-txt">
                                                  {dayjs(
                                                    row.dtVencimento,
                                                  ).format(`DD/MM/YYYY`)}
                                                </span>
                                              </div>
                                              <div className="col-6"></div>
                                            </div>
                                            <div className="row row-mx-50">
                                              <div className="col-6">
                                                <span className="row-span-small-txt mt-16">
                                                  Data Limite
                                                </span>
                                                <br />
                                                <span className="table-txt">
                                                  {loadDataLimite ? (
                                                    <div
                                                      className="yellow-spinner boleto-row-download-spinner "
                                                      role="status"
                                                    ></div>
                                                  ) : (
                                                    dayjs(dataLimite).format(
                                                      `DD/MM/YYYY`,
                                                    )
                                                  )}
                                                </span>
                                              </div>
                                              <div className="col-6"></div>
                                            </div>
                                            <div className="row row-mx-50">
                                              <div className="col-6">
                                                <span className="row-span-small-txt mt-16">
                                                  Data do pagamento
                                                </span>
                                                <br />
                                                <>
                                                  {row.dtPagamento !== `` && (
                                                    <span className="table-txt">
                                                      {dayjs(
                                                        row.dtPagamento,
                                                      ).format(`DD/MM/YYYY`)}
                                                    </span>
                                                  )}
                                                  {row.dtPagamento === `` && (
                                                    <span className="table-txt">
                                                      Aguardando pagamento
                                                    </span>
                                                  )}
                                                </>
                                              </div>
                                              <div className="col-6">
                                                {/* <span className="row-span-small-txt mt-16">
                                                Data limite
                                              </span>
                                              <br />
                                              <span className="table-txt">
                                                19/03/2022
                                              </span> */}
                                              </div>
                                            </div>
                                            {/* <span className="row-span-small-txt mt-16">
                                            Linha digitável
                                          </span>
                                          <span className="table-txt">
                                            00789300004567899000093939292910000000000
                                          </span> */}
                                          </div>
                                        </div>
                                        <div className="col">
                                          <span className="row-txt-bold">
                                            Dados do pagamento
                                          </span>
                                          <div className="collapse-payer-data height-fit">
                                            <div className="row row-mx-50">
                                              <div className="col-6">
                                                <span className="row-span-small-txt mt-16">
                                                  Valor original
                                                </span>
                                                <br />
                                                <span className="table-txt">
                                                  {row.vlrBoleto.toLocaleString(
                                                    `pt-br`,
                                                    {
                                                      style: `currency`,
                                                      currency: `BRL`,
                                                    },
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="row row-mx-50">
                                              <div className="col-6">
                                                <span className="row-span-small-txt mt-16">
                                                  Valor pago
                                                </span>
                                                <br />
                                                <>
                                                  {row.vlrPago && (
                                                    <span className="table-txt">
                                                      {row.vlrPago.toLocaleString(
                                                        `pt-br`,
                                                        {
                                                          style: `currency`,
                                                          currency: `BRL`,
                                                        },
                                                      )}
                                                    </span>
                                                  )}
                                                  {!row.vlrPago && (
                                                    <span className="table-txt">
                                                      R$ 0,00
                                                    </span>
                                                  )}
                                                </>
                                              </div>
                                              <div className="col-12">
                                                <span className="row-span-small-txt mt-16">
                                                  Linha Digitavel
                                                </span>
                                                <br />
                                                <>
                                                  <span className="table-txt">
                                                    {codBarras2LinhaDigitavel(
                                                      row.codigoBarras,
                                                      true,
                                                    )}
                                                  </span>
                                                </>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row fl-right">
                                        <DownloadPdf
                                          row={row}
                                          type={`details`}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
                {dataArray.length === 0 && !loadingData && (
                  <div className="mb-not-found">
                    <span className="mb-not-found-txt">
                      Não foram encontrados registros de boletos no período
                    </span>
                  </div>
                )}
              </div>
              {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< PAGINATION >>>>>>>>>>>>>>>>>>>>>>>>>>>>. */}
              {totalPages > 1 && (
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              )}
            </div>
            {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< MODALS >>>>>>>>>>>>>>>>>>>>>>>>>>>>. */}
            <SuccessModal
              isOpen={showModal}
              width={`500px`}
              height={modalType === 1 ? `440px` : `350`}
              onClose={() => {
                setSendEmailOption(false)
                setRecipientEmail(``)
                addShowModal(false)
              }}
            >
              {modalType === 2 && (
                <h1 className="pix-modal-sucess-h1">
                  Boleto cancelado com <br /> sucesso!
                </h1>
              )}

              {modalType === 1 && (
                <>
                  <h1 className="pix-modal-sucess-h1">
                    Boleto criado com <br /> sucesso!
                  </h1>
                  <div className="bm-time-info-div">
                    <span className="bm-time-info">
                      Em até 2 minutos seu boleto estará disponível
                    </span>
                  </div>
                  <div className="mb-modal-ok-div">
                    <button
                      className="mb-modal-ok-btn"
                      onClick={() => addShowModal(false)}
                    >
                      OK!
                    </button>
                  </div>
                </>
              )}

              {/* {modalType === 1 && (
              <>
                {pdfModalLoading ? (
                  <div className="mb-save-pdf-spinner-div">
                    <img width={50} src={spinner} alt="loading" />
                  </div>
                ) : (
                  <>
                    {!pdfModalError ? (
                      <>
                        <div className="mb-save-pdf-div">
                          <img src={save} />
                          <a
                            href={base64PdfModal}
                            download={`${pdfModalName}.pdf`}
                            className="mb-save-pdf-txt"
                          >
                            Salvar pdf
                          </a>
                        </div>
                        <div className="mb-modal-ok-div">
                          <button
                            className="mb-modal-ok-btn"
                            onClick={() => setShowModal(false)}
                          >
                            OK!
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )} */}

              {modalType === 3 && (
                <>
                  <h1 className="email-boleto-success">
                    Boleto enviado para <br />
                    {recipientEmail} com sucesso!
                  </h1>
                  <div className="mb-modal-ok-div">
                    <button
                      className="mb-modal-ok-btn"
                      onClick={() => addShowModal(false)}
                    >
                      OK!
                    </button>
                  </div>
                </>
              )}
            </SuccessModal>
            <ErrorModal
              isOpen={showErrorModal}
              width={`500px`}
              height={`auto`}
              onClose={() => addShowErrorModal(false)}
            >
              <h1 className="pix-modal-sucess-h1">
                {modalErrorMessage !== ``
                  ? modalErrorMessage
                  : `Houve um erro interno ao tentar obter os boletos, tente novamente mais tarde.`}
              </h1>
            </ErrorModal>
          </body>
        </>
      )}
    </>
  )
}
export default BoletoManager
