import Avatar from "../../../../assets/images/navbar-banking/avatar.svg"
import Menu from "../../../../assets/images/navbar-banking/menu.svg"
import SVGInject from "@iconfu/svg-inject"
import Switcher from "../switcher"
import "./styles.scss"
import React, { useContext, useEffect, useRef, useState } from "react"
import { AuthContext, SHOW_TIMER_TIME } from "../../../../context/AuthProvider"
import { ThemeContext } from "../../../../context/ThemeContext"
import Logo from "../../../../assets/images/kikkin_white_logo.svg"
import { Link } from "react-router-dom"
import Eye from "../../../../assets/images/navbar-banking/navbar_eye.svg"
import ClosedEye from "../../../../assets/images/navbar-banking/navbar_eye_closed.svg"
import arrow from "../../../../assets/images/arrow-right-yellow.svg"
import dayjs from "dayjs"
import Timer from "../../../../assets/images/navbar-banking/timer.svg"
import { BalanceContext } from "../../../../context/BalanceProvider"

interface NavbarProps {
  isLogin?: boolean
  isBanking?: boolean
  hide?: boolean
}

interface MenuProps {
  onClose: () => void
}

const NavbarMobile = ({ hide }: NavbarProps) => {
  const { user, current_account, remaningTime } = useContext(AuthContext)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [showBalance, setShowBalance] = useState(true)
  const [showAccount, setShowAccount] = useState(false)
  const { balance, loadingBalance } = useContext(BalanceContext)
  const [idleTimer, setIdleTimer] = useState<number>()
  const timerRef = useRef(setInterval(() => {}, 1000))

  useEffect(() => {
    clearInterval(timerRef.current)
    if (remaningTime <= SHOW_TIMER_TIME) {
      setIdleTimer(remaningTime)
      timerRef.current = setInterval(() => {
        setIdleTimer((t) => (t ? t - 1 : 0))
      }, 1000)
    } else {
      clearInterval(timerRef.current)
      setIdleTimer(undefined)
    }
  }, [remaningTime])

  if (hide) {
    return <></>
  }

  return (
    <div>
      {isMenuOpen && <MobileMenu onClose={() => setIsMenuOpen(false)} />}
      <div className="navbar-banking-mobile">
        <div className="d-flex align-items-center">
          <img
            className="navbar-banking-avatar"
            src={Avatar}
            onLoad={(e) => SVGInject(e.target)}
          />
          <div className="span-navbar-user">
            Olá,
            <div className="font-bold mb-1"> {user?.nome}</div>
            <div>
              {current_account?.conta}-{current_account?.contaDigito}
            </div>
          </div>
          <div className="ml-auto">
            <div className="d-flex align-items-center justify-content-end">
              <div>
                <span
                  className={showBalance ? `` : `d-none`}
                  onClick={() => setShowBalance(false)}
                >
                  <img src={Eye} onLoad={(e) => SVGInject(e.target)} />
                </span>
                <span
                  className={showBalance ? `d-none` : ``}
                  onClick={() => setShowBalance(true)}
                >
                  <img src={ClosedEye} onLoad={(e) => SVGInject(e.target)} />
                </span>
              </div>
              <div className="ml-3" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <img src={Menu} onLoad={(e) => SVGInject(e.target)} />
              </div>
            </div>
            {idleTimer ? (
              <div className="timer-container mt-2">
                <img src={Timer} height="50%" />
                {dayjs(0).second(idleTimer).format(`mm:ss`)}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {!showAccount ? (
          <div className="mt-5">
            <div>Saldo disponível</div>
            <div className="d-flex align-items-center">
              <div className="flex-1">
                {!loadingBalance ? (
                  showBalance ? (
                    <span className="navbar-mobile-value-span">
                      {balance?.toLocaleString(`pt-BR`, {
                        style: `currency`,
                        currency: `BRL`,
                      })}
                    </span>
                  ) : (
                    <span className="navbar-mobile-value-span">R$ ***</span>
                  )
                ) : (
                  <div className="navbar-spinner m-1"></div>
                )}
              </div>
              <div>
                <button
                  className={`yellow-arrow-button-right`}
                  onClick={() => setShowAccount(true)}
                >
                  <img src={arrow} onLoad={(e) => SVGInject(e.target)} />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-5 text-align-right">
            <div>Conta</div>
            <div className="d-flex align-items-center">
              <div>
                <button
                  className={`yellow-arrow-button-right flip-icon`}
                  onClick={() => setShowAccount(false)}
                >
                  <img src={arrow} onLoad={(e) => SVGInject(e.target)} />
                </button>
              </div>
              <div className="flex-1">
                <span className="navbar-mobile-value-span">
                  {current_account?.conta}-{current_account?.contaDigito}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      <Switcher />
    </div>
  )
}

const MobileMenu = ({ onClose }: MenuProps) => {
  const { logo, logoutRedirectUri } = useContext(ThemeContext)
  const { logout } = useContext(AuthContext)
  const [opening, setOpening] = useState(false)

  useEffect(() => setOpening(true), [])

  const handleRequestClose = () => {
    setOpening(false)
    setTimeout(() => {
      onClose()
    }, 200)
  }

  return (
    <div className="mobile-menu-wrapper">
      <div
        className="mobile-menu-close-area"
        onClick={handleRequestClose}
      ></div>
      <div className={`mobile-menu-container ${!opening && `closed`}`}>
        <div onClick={handleRequestClose}>
          <img
            style={{ width: `100%`, marginBottom: 20, marginTop: 20 }}
            src={logo || Logo}
            onLoad={(e) => SVGInject(e.target)}
          />
        </div>
        <Link to="/account-management" className="mobile-menu-item">
          Gerenciar conta
        </Link>
        <div
          className="mobile-menu-item"
          onClick={() => logout(logoutRedirectUri)}
        >
          Sair
        </div>
      </div>
    </div>
  )
}

export default NavbarMobile
