import { useState } from "react"
import { useContext } from "react"
import { OnboardingContext } from "../../../../context/OnboardingContext"
import services from "../../../../services/svcmesh"
import OnboardingCamera from "../../../features/OnboardingCamera"

const IdentityCam = () => {
  const { goBack, saveIdentity, photo, id, documentType } =
    useContext(OnboardingContext)

  const [firstPic, setFirstPic] = useState(``)
  const [secondPic, setSecondPic] = useState(``)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(``)

  const handleFirstPic = async (picUrl: string) => {
    setError(``)
    setLoading(true)
    try {
      setFirstPic(picUrl)
      const res = await services.ocrService.verifyIdentity(
        picUrl,
        id,
        documentType,
      )
      console.log(res.data)
      if (res.data?.status && res.data?.status === `ok`) {
        setFirstPic(picUrl)
      } else {
        setError(`Não foi possível ler os dados. Tente novamente`)
      }
    } catch (error: any) {
      setError(
        `Não foi possível ler os dados do seu documento. Por favor tente novamente`,
      )
    }
    setLoading(false)
  }

  const handleSecondPic = async (picUrl: string) => {
    setError(``)
    setLoading(true)
    try {
      const res = await services.ocrService.verifyIdentity(
        picUrl,
        id,
        documentType,
      )
      console.log(res)
      if (res.data?.status && res.data?.status === `ok`) {
        setSecondPic(picUrl)
        saveIdentity()
      } else {
        setError(`Não foi possível ler os dados. Tente novamente`)
      }
    } catch (error: any) {
      setError(
        `Não foi possível ler os dados do seu documento. Por favor tente novamente`,
      )
    }
    setLoading(false)
  }

  return (
    <div className="onboarding-cam-page">
      <div className="w-100">
        <button onClick={goBack} className="onboarding-close-btn" />
      </div>
      {!firstPic && (
        <OnboardingCamera
          onConfirm={handleFirstPic}
          photoText="Posicione o lado com foto"
          loading={loading}
          clearError={() => setError(``)}
          error={error}
          initialPhoto={photo}
        />
      )}
      {firstPic && !secondPic && (
        <OnboardingCamera
          onConfirm={handleSecondPic}
          photoText="Posicione o verso"
          loading={loading}
          clearError={() => setError(``)}
          error={error}
        />
      )}
    </div>
  )
}

export default IdentityCam
