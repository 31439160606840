import { useState, FocusEvent, useEffect } from "react"
import { useForm } from "react-hook-form"
import "./styles.scss"
import InputMask from "react-input-mask"
import { useContext } from "react"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { BcStep1 } from "../../../../../../typings/boleto-creation.dto"
import { BoletoContext } from "../../../../../../context/BoletoContext"
import Select from "react-select"
import { useResposive } from "../../../../../../hooks/useResponsive"
import BoletoCreationStep1Mobile from "./mobile"
import { Contact } from "k4n-svcmesh-sdk"
import services from "../../../../../../services/svcmesh"
interface InputFocus {
  cpf?: boolean
  recipient?: boolean
  email?: boolean
  phone?: boolean
  zip?: boolean
  street?: boolean
  number?: boolean
  complement?: boolean
  district?: boolean
  city?: boolean
  state?: boolean
}

export interface Props {
  handleCancel: () => void
}

const BoletoCreationStep1: React.FC<Props> = ({ handleCancel }) => {
  const defaultFocus = {
    cpf: false,
    recipient: false,
    email: false,
    phone: false,
    zip: false,
    street: false,
    number: false,
    complement: false,
    district: false,
    city: false,
    state: false,
  } as InputFocus

  interface Option {
    value?: string
    label?: string
  }

  const [focusList, setFocusList] = useState<InputFocus>(defaultFocus)
  const [saveAsFavorite, setSaveAsFavorite] = useState(false)
  const { changeBoletoStep } = useContext(BankingNavigationContext)
  const { addBcStep1, favorites } = useContext(BoletoContext)
  const [fixedFavorites, setFixedFavorites] = useState<Option[]>([])
  const [inputFavorite, setInputFavorite] = useState(null)
  const [selectedFavorite, setSelectedFavorite] = useState<Contact>({})
  const [cpfCnpjMask, setCpfCnpjMask] = useState(``)
  const [phoneMask, setPhoneMask] = useState(``)
  const { isTabletOrMobile } = useResposive()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm()

  useEffect(
    function () {
      if (favorites.length > 0 && fixedFavorites.length === 0) {
        const fixedFavoritesArray: Option[] = []
        favorites.forEach((f) => {
          if (f.cpf && f.nome && f.cpf != ` `) {
            fixedFavoritesArray.push({
              value: f.cpf,
              label: f.nome,
            })
          }
        })
        if (fixedFavoritesArray.length > 0)
          setFixedFavorites(fixedFavoritesArray)
      }

      if (selectedFavorite && selectedFavorite.cpf) {
        const value = buildCpfCnpjMask(selectedFavorite.cpf)
        setValue(`documento`, value)
        setCpfCnpjMask(value)
        setValue(`recipient`, selectedFavorite.nome)
        if (selectedFavorite.emails && selectedFavorite.emails.length > 0) {
          setValue(`email`, selectedFavorite.emails[0].email)
        }
        if (selectedFavorite.phones && selectedFavorite.phones.length > 0) {
          const maskedPhone = buildPhoneMask(
            selectedFavorite.phones[0].phone || ``,
          )
          setValue(`phone`, maskedPhone)
          setPhoneMask(maskedPhone)
        }
        if (
          selectedFavorite.addresses &&
          selectedFavorite.addresses.length > 0
        ) {
          setValue(`zip`, selectedFavorite.addresses[0].cep)
          setValue(`street`, selectedFavorite.addresses[0].logradouro)
          setValue(`city`, selectedFavorite.addresses[0].cidade)
          setValue(`state`, selectedFavorite.addresses[0].uf)
          setValue(`district`, selectedFavorite.addresses[0].bairro)
          setValue(`number`, selectedFavorite.addresses[0].numero)
          if (selectedFavorite.addresses[0].complemento)
            setValue(`complement`, selectedFavorite.addresses[0].complemento)
        }
      }
    },
    [favorites, selectedFavorite],
  )
  useEffect(function () {
    if (selectedFavorite.cpf) {
      setSaveAsFavorite(true)
    }
  }, [])

  const handleFocus = (
    event:
      | FocusEvent<HTMLInputElement>
      | FocusEvent<HTMLSelectElement, Element>,
  ) => {
    switch (event.target.id) {
      case `cpf`:
        setFocusList({ cpf: true })
        break
      case `recipient`:
        setFocusList({ recipient: true })
        break
      case `email`:
        setFocusList({ email: true })
        break
      case `phone`:
        setFocusList({ phone: true })
        break
      case `zip`:
        setFocusList({ zip: true })
        break
      case `street`:
        setFocusList({ street: true })
        break
      case `number`:
        setFocusList({ number: true })
        break
      case `complement`:
        setFocusList({ complement: true })
        break
      case `district`:
        setFocusList({ district: true })
        break
      case `city`:
        setFocusList({ city: true })
        break
      case `state`:
        setFocusList({ state: true })
        break
    }
  }

  const handleFocusEnd = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target.value === ``) {
      setFocusList(defaultFocus)
    }
  }

  const handleSelectFocusEnd = (event: FocusEvent<HTMLSelectElement>) => {
    if (parseInt(event.target.value) === 0) {
      setFocusList(defaultFocus)
    }
  }

  const onSubmit = (data: any) => {
    const saveObj = {
      cpfCnpj: data.documento.replaceAll(`.`, ``).replaceAll(`-`, ``),
      recipient: data.recipient,
      email: data.email,
      phone: data.phone,
      cep: data.zip,
      logradouro: data.street,
      numero: data.number,
      bairro: data.district,
      cidade: data.city,
      uf: data.state,
      favorite: selectedFavorite.cpf ? true : saveAsFavorite,
    } as BcStep1

    if (data.complement) saveObj.complemento = data.complement

    addBcStep1(saveObj)
    handleClickScroll()
    changeBoletoStep(2)
  }

  function handleCpfCnpjChange(e: any) {
    e.preventDefault()
    let value = e.currentTarget.value
    value = buildCpfCnpjMask(value)
    setCpfCnpjMask(value)
  }

  function handlePhoneChange(e: any) {
    let value = e.currentTarget.value
    value = buildPhoneMask(value)
    setPhoneMask(value)
  }

  const buildCpfCnpjMask = (cpfCnpj: string) => {
    let value = cpfCnpj.replace(/\D/g, ``)
    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d)/, `$1.$2`)
      value = value.replace(/(\d{3})(\d)/, `$1.$2`)
      value = value.replace(/(\d{3})(\d{1,2})$/, `$1-$2`)
    } else {
      value = value.replace(/^(\d{2})(\d)/, `$1.$2`)
      value = value.replace(/^(\d{2})\.(\d{3})(\d)/, `$1.$2.$3`)
      value = value.replace(/\.(\d{3})(\d)/, `.$1/$2`)
      value = value.replace(/(\d{4})(\d)/, `$1-$2`)
    }
    return value
  }

  const buildPhoneMask = (phone: string) => {
    let value = phone.replace(/\D/g, ``)
    if (value.length <= 11) {
      value = value.replace(/(\d{2})/, `($1) `)
      value = value.replace(/(\d{4})(\d{4})$/, `$1-$2`)
    }
    return value
  }

  const handleClickScroll = () => {
    const scroll = document.getElementById(`header`)
    if (scroll) {
      scroll.scrollIntoView({ behavior: `smooth` })
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // borderBottom: `1px dotted pink`,
      color: state.isSelected ? `black` : `black`,
      padding: 20,
      background: state.isSelected || state.isFocused ? `#e5e5e5` : `#FFFFFF`,
      "&:hover": {
        background: `#e5e5e5`,
        cursor: `pointer`,
      },
    }),
    input: (provided, state) => ({
      ...provided,
      "&:hover": {
        cursor: `text`,
      },
    }),
    control: (base) => ({
      ...base,
      boxShadow: `none`,
      border: 0,
      borderRadius: `16px`,
      height: `54px`,
      background: `var(--k4n-secondary-light-color)`,
      paddingLeft: `10px`,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: `#fff`, // Change placeholder color to white
    }),
  }

  const handleFavoriteChange = (e: any) => {
    setInputFavorite(e)
    if (e && e.value) {
      const favorite = favorites.filter((f) => f.cpf === e.value)
      setSelectedFavorite(favorite[0])
    } else {
      setSelectedFavorite({})
      reset()
      setCpfCnpjMask(``)
      setValue(`zip`, ``)
      setValue(`phone`, ``)
      setValue(`state`, `0`)
    }
  }

  const handleCompleteAddress = async (
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    clearErrors(`zip`)
    const zipCode = event.currentTarget.value.replace(`-`, ``)
    const res = await services.zipCodeService.consultZipCode(zipCode)
    if (res.data.erro === true) {
      setError(`zip`, { message: `Verifique o CEP.` })
    }
    setValue(`street`, res.data.logradouro)
    setValue(`state`, res.data.uf)
    setValue(`city`, res.data.localidade)
    setValue(`district`, res.data.bairro)
  }

  return (
    <>
      {!isTabletOrMobile ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)} className="bc-step">
            <div className="bc-step-header">
              <div>
                <h1 className="bc-step-title vertical-center">
                  Informe os dados do pagador
                </h1>
              </div>
              <div className="bc-select-favorite-div">
                <div className="bc-favorite-right">
                  <div className="bc-favorite-size">
                    <Select
                      noOptionsMessage={({ inputValue }) =>
                        !inputValue ? `` : `Nenhum resultado encontrado`
                      }
                      options={fixedFavorites}
                      placeholder="Selecione dos Favoritos"
                      styles={customStyles}
                      isMulti={false}
                      isClearable={true}
                      value={inputFavorite}
                      onChange={handleFavoriteChange}
                    />
                  </div>
                </div>
                {/* <input
                list="favorites"
                type="text"
                placeholder="Selecione dos Favoritos"
                className="bc-select-favorite vertical-center"
              />
              <datalist dir="auto" id="favorites">
                {favorites.length > 0 &&
                  favorites.map((f, i) => (
                    <option key={i} value={`Nome: ${f.nome} - CPF: ${f.cpf}`} />
                  ))}
              </datalist> */}
              </div>
            </div>
            <div className="bc-step-1-input-row-1 mx-wd-row ">
              <label
                className={
                  focusList.cpf
                    ? `boleto-creation-input-label show-input`
                    : `boleto-creation-input-label`
                }
                htmlFor="cpf"
              >
                CPF/CNPJ
              </label>
              <input
                id="cpf"
                type="text"
                value={cpfCnpjMask}
                className="boleto-creation-input"
                placeholder="CPF/CNPJ"
                onFocus={handleFocus}
                onKeyPress={(event) => {
                  if (event.key === `Enter`) {
                    event.preventDefault()
                  }
                }}
                {...register(`documento`, {
                  required: true,
                  pattern:
                    /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/,
                  onBlur: handleFocusEnd,
                  onChange: handleCpfCnpjChange,
                })}
              />

              {errors.cpf && (
                <p className="bc-error-msg">Verifique o CPF ou CNPJ.</p>
              )}
            </div>

            <div className="row bc-step-1-input-row-2 mx-wd-row ">
              <div className="col mx-wd-339">
                <label
                  className={
                    focusList.recipient
                      ? `boleto-creation-input-label show-input`
                      : `boleto-creation-input-label`
                  }
                  htmlFor="recipient"
                >
                  Nome do destinatário
                </label>
                <input
                  id="recipient"
                  type="text"
                  className="boleto-creation-input"
                  onFocus={handleFocus}
                  placeholder="Nome do destinatário"
                  onKeyPress={(event) => {
                    if (event.key === `Enter`) {
                      event.preventDefault()
                    }
                  }}
                  {...register(`recipient`, {
                    required: true,
                    onBlur: handleFocusEnd,
                    pattern: /^(([A-zÀ-ÿ]+)(\ )?){0,}$/,
                  })}
                />
                {errors.recipient && (
                  <p className="bc-error-msg">Verifique o destinatário.</p>
                )}
              </div>
              <div className="col mx-wd-339">
                <label
                  className={
                    focusList.email
                      ? `boleto-creation-input-label show-input`
                      : `boleto-creation-input-label`
                  }
                  htmlFor="email"
                >
                  E-mail
                </label>
                <input
                  id="email"
                  type="text"
                  className="boleto-creation-input"
                  placeholder="E-mail"
                  onFocus={handleFocus}
                  onKeyPress={(event) => {
                    if (event.key === `Enter`) {
                      event.preventDefault()
                    }
                  }}
                  {...register(`email`, {
                    required: true,
                    onBlur: handleFocusEnd,
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />

                {errors.email && (
                  <p className="bc-error-msg">Verifique o E-mail.</p>
                )}
              </div>
              <div className="col mx-wd-339 input-phone-mt-24">
                <label
                  className={
                    focusList.phone
                      ? `boleto-creation-input-label show-input`
                      : `boleto-creation-input-label`
                  }
                  htmlFor="phone"
                >
                  Telefone
                </label>

                <input
                  id="phone"
                  value={phoneMask}
                  type="text"
                  onFocus={handleFocus}
                  placeholder="Telefone"
                  className="boleto-creation-input"
                  maxLength={15}
                  onKeyPress={(event) => {
                    if (event.key === `Enter`) {
                      event.preventDefault()
                    }
                  }}
                  {...register(`phone`, {
                    required: true,
                    onBlur: handleFocusEnd,
                    pattern: /^\(\d{2}\)\s\d{4,5}\-\d{4}$/,
                    onChange: handlePhoneChange,
                  })}
                />
                {errors.phone && (
                  <p className="bc-error-msg">Verifique o telefone.</p>
                )}
              </div>
              <div className="col"></div>
            </div>

            <div className="bc-step-1-header-2">
              <h1 className="bc-step-1-title vertical-center">
                Informe o endereço
              </h1>
            </div>

            <div className="bc-step-1-input-row-3 mx-wd-row ">
              <label
                className={
                  focusList.zip
                    ? `boleto-creation-input-label show-input`
                    : `boleto-creation-input-label`
                }
                htmlFor="zip"
              >
                CEP
              </label>

              <InputMask
                id="cep"
                mask="99999-999"
                maskPlaceholder={null}
                type="text"
                placeholder="CEP"
                className="boleto-creation-input"
                onFocus={handleFocus}
                onKeyPress={(event) => {
                  if (event.key === `Enter`) {
                    event.preventDefault()
                  }
                }}
                {...register(`zip`, {
                  required: true,
                  pattern: /^\d{5}-\d{3}$/,
                  onChange: (e) => {
                    handleFocusEnd
                    handleCompleteAddress(e)
                  },
                })}
              />
              {errors.zip && <p className="bc-error-msg">Verifique o CEP.</p>}
            </div>

            <div className="row bc-step-1-input-row-4 mx-wd-row ">
              <div className="col mx-wd-649">
                <label
                  className={
                    focusList.street
                      ? `boleto-creation-input-label show-input`
                      : `boleto-creation-input-label`
                  }
                  htmlFor="street"
                >
                  Endereço
                </label>
                <input
                  id="street"
                  type="text"
                  disabled={true}
                  className="boleto-creation-input mi-wd-625 bc-input-disabled-bg"
                  onFocus={handleFocus}
                  placeholder="Endereço"
                  onKeyPress={(event) => {
                    if (event.key === `Enter`) {
                      event.preventDefault()
                    }
                  }}
                  {...register(`street`, {
                    required: true,
                    onBlur: handleFocusEnd,
                  })}
                />

                {errors.street && (
                  <p className="bc-error-msg">Verifique o endereço.</p>
                )}
              </div>
              <div className="col mx-wd-173">
                <label
                  className={
                    focusList.number
                      ? `boleto-creation-input-label show-input`
                      : `boleto-creation-input-label`
                  }
                  htmlFor="email"
                >
                  Número
                </label>
                <input
                  id="number"
                  type="text"
                  className="boleto-creation-input mx-wd-149"
                  placeholder="Número"
                  onFocus={handleFocus}
                  onKeyPress={(event) => {
                    if (event.key === `Enter`) {
                      event.preventDefault()
                    }
                  }}
                  {...register(`number`, {
                    required: true,
                    pattern: /^[0-9]+$/,
                    onBlur: handleFocusEnd,
                  })}
                />

                {errors.number && (
                  <p className="bc-error-msg">Verifique o número.</p>
                )}
              </div>
              <div className="col input-complement-mt-24">
                <label
                  className={
                    focusList.complement
                      ? `boleto-creation-input-label show-input`
                      : `boleto-creation-input-label`
                  }
                  htmlFor="complement"
                >
                  Complemento
                </label>
                <input
                  id="complement"
                  type="text"
                  className="boleto-creation-input"
                  placeholder="Complemento"
                  onFocus={handleFocus}
                  onKeyPress={(event) => {
                    if (event.key === `Enter`) {
                      event.preventDefault()
                    }
                  }}
                  {...register(`complement`, { onBlur: handleFocusEnd })}
                />
              </div>
            </div>

            <div className="row bc-step-1-input-row-4 mx-wd-row ">
              <div className="col mx-wd-487">
                <label
                  className={
                    focusList.district
                      ? `boleto-creation-input-label show-input`
                      : `boleto-creation-input-label`
                  }
                  htmlFor="district"
                >
                  Bairro
                </label>
                <input
                  id="district"
                  type="text"
                  className="boleto-creation-input mi-wd-463 bc-input-disabled-bg"
                  disabled={true}
                  // onFocus={handleFocus}
                  placeholder="Bairro"
                  onKeyPress={(event) => {
                    if (event.key === `Enter`) {
                      event.preventDefault()
                    }
                  }}
                  {...register(`district`, {
                    required: true,
                    pattern: /^(([a-zA-Zà-ùÀ-Ù0-9´'()]+)(\ )?){0,}$/,
                    onBlur: handleFocusEnd,
                  })}
                />

                {errors.district && (
                  <p className="bc-error-msg">Verifique o bairro.</p>
                )}
              </div>
              <div className="col mx-wd-339">
                <label
                  className={
                    focusList.city
                      ? `boleto-creation-input-label show-input`
                      : `boleto-creation-input-label`
                  }
                  htmlFor="city"
                >
                  Cidade
                </label>
                <input
                  id="city"
                  type="text"
                  className="boleto-creation-input bc-input-disabled-bg"
                  disabled={true}
                  placeholder="Cidade"
                  // onFocus={handleFocus}
                  onKeyPress={(event) => {
                    if (event.key === `Enter`) {
                      event.preventDefault()
                    }
                  }}
                  {...register(`city`, {
                    required: true,
                    pattern: /^(([a-zA-Zà-ùÀ-Ù'´]+)(\ )?){0,}$/,
                    onBlur: handleFocusEnd,
                  })}
                />

                {errors.city && (
                  <p className="bc-error-msg">Verifique a cidade.</p>
                )}
              </div>

              <div className="col input-state-mt-24">
                <label
                  className={
                    focusList.city
                      ? `boleto-creation-input-label show-input`
                      : `boleto-creation-input-label`
                  }
                  htmlFor="city"
                >
                  Estado
                </label>

                <input
                  id="state"
                  type="text"
                  className="boleto-creation-input bc-input-disabled-bg"
                  disabled={true}
                  placeholder="Estado"
                  onKeyPress={(event) => {
                    if (event.key === `Enter`) {
                      event.preventDefault()
                    }
                  }}
                  {...register(`state`, {
                    required: true,
                    minLength: 2,
                    onBlur: handleSelectFocusEnd,
                  })}
                />

                {/*  <select
                  className="bc-step-1-select bc-input-value-txt"
                  disabled={true}
                  id="state"
                  onFocus={handleFocus}
                  {...register(`state`, {
                    required: true,
                    minLength: 2,
                    onBlur: handleSelectFocusEnd,
                  })}
                >
                  <option
                    value="0"
                    disabled
                    selected
                    className="bc-input-disabled-bg"
                  >
                    Estado
                  </option>
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goías</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraíma</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                </select> */}

                {errors.state && (
                  <p className="bc-error-msg">Verifique o estado.</p>
                )}
              </div>
            </div>

            <div className="row bc-step-1-input-row-check mx-wd-row ">
              {!selectedFavorite.cpf && (
                <>
                  <input
                    onClick={() => setSaveAsFavorite(!saveAsFavorite)}
                    type="button"
                    className={
                      saveAsFavorite
                        ? `bc-step1-checkbox bc-step1-checked`
                        : `bc-step1-checkbox`
                    }
                  />
                  <span className="save-as-favorite-txt">
                    Salvar como favorito
                  </span>
                </>
              )}
            </div>

            <div className="bc-row-btns">
              <button className="bc-btn-transparent" onClick={handleCancel}>
                Cancelar
              </button>
              <button type="submit" className="bc-btn-yellow">
                Próximo
              </button>
            </div>
          </form>
        </>
      ) : (
        <>
          <BoletoCreationStep1Mobile
            onSubmit={onSubmit}
            handleFocus={handleFocus}
            handleFocusEnd={handleFocusEnd}
            handleCpfCnpjChange={handleCpfCnpjChange}
            handlePhoneChange={handlePhoneChange}
            handleSelectFocusEnd={handleSelectFocusEnd}
            focusList={focusList}
            phoneMask={phoneMask}
            cpfCnpjMask={cpfCnpjMask}
            handleCancel={handleCancel}
            inputFavorite={inputFavorite}
            fixedFavorites={fixedFavorites}
            selectedFavorite={selectedFavorite}
            saveAsFavorite={saveAsFavorite}
            setSaveAsFavorite={setSaveAsFavorite}
            favorites={favorites}
            setFixedFavorites={setFixedFavorites}
            buildCpfCnpjMask={buildCpfCnpjMask}
            setCpfCnpjMask={setCpfCnpjMask}
            buildPhoneMask={buildPhoneMask}
            setPhoneMask={setPhoneMask}
            setSelectedFavorite={setSelectedFavorite}
            setInputFavorite={setInputFavorite}
          />
        </>
      )}
    </>
  )
}
export default BoletoCreationStep1
