import { useNavigate } from "react-router-dom"
import { useResposive } from "../../../../hooks/useResponsive"
import "./styles.scss"

export const Final = () => {
  const { isMobile } = useResposive()
  const navigate = useNavigate()

  const handleClick = () => {
    // goto home page
    navigate(`/`)
  }

  return (
    <div className="onboarding-pj-form-container pl-1 pr-1">
      {/* Final step */}
      <div className="start-text-container">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-md-8 no-padding-col">
            <span className="start-text-title">
              Viu? Foi rápido!
              <br /> Estamos processando seu pedido
            </span>
            <div>
              <span className="text-finish">
                Em breve você receberá um SMS com a senha temporária.
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* End button */}
      <div
        className={
          !isMobile ? `onboarding-pj-flex-end` : `row d-flex align-items-center`
        }
      >
        <button onClick={handleClick} className="onboarding-btn-primary">
          <span>Acessar meu kikkin</span>
        </button>
      </div>
    </div>
  )
}
