import { useContext } from "react"
import close from "../../../../../../../assets/images/clear_x.svg"
import share from "../../../../../../../assets/images/share_yellow.svg"
import transfer from "../../../../../../../assets/images/transfer.svg"
import SVGInject from "@iconfu/svg-inject"
import "./styles.scss"
import { PixStorageContext } from "../../../../../../../context/PixStorageProvider"
import { formattedCurrency } from "../../../../../../../services/banking"
import { AuthContext } from "../../../../../../../context/AuthProvider"
import dayjs from "dayjs"

const PixReceiptMobile = ({ handlePageChange, handleDownloadImage }) => {
  const { pixPaymentStep1, pixPaymentStep3 } = useContext(PixStorageContext)
  const { user, current_account } = useContext(AuthContext)

  return (
    <div className="pix-receipt-content-mobile">
      <div id="capture" className="pix-receipt-capture-mobile">
        <div className="pix-receipt-nav-img">
          <span
            className="pix-receipt-img-close"
            onClick={() => handlePageChange(1)}
          >
            <img
              src={close}
              width="24"
              height="24"
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
          </span>
          <span onClick={handleDownloadImage}>
            <img
              className="pix-receipt-img-share"
              src={share}
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
          </span>
        </div>
        <div>
          <div className="pix-receipt-content-title">
            <h1 className="pix-receipt-title-mobile">
              {pixPaymentStep3.agendamento === true
                ? `Comprovante de agendamento do Pix`
                : `Comprovante de Pix`}
            </h1>
            {pixPaymentStep3.agendamento === true ? (
              <span className="pix-payment-step3-txt">
                Agendado para{` `}
                <b>{dayjs().format(`DD/MM/YYYY`)}</b>
              </span>
            ) : (
              <span className="pix-payment-step3-txt">
                Realizado em{` `}
                <b>{dayjs().format(`DD/MM/YYYY`)}</b>
              </span>
            )}
          </div>
          <div className="pix-receipt-table-mobile">
            <span className="pix-receipt-h1-mobile bold">
              Valor da <br /> transferência
            </span>
            <span className="pix-payment-step3-txt-value text-align-right">
              {formattedCurrency(pixPaymentStep3.valor)}
            </span>
          </div>
          <div className="pix-receipt-mobile ">
            <span className="pix-receipt-h1-mobile bold">Mensagem</span>
            <span className="pix-payment-step3-txt text-align-right">
              {pixPaymentStep1.message}
            </span>
          </div>
          <div className="pix-receipt-img-title">
            <img
              className="pix-content-img"
              src={transfer}
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
            <span className="pix-content-txt">Destino</span>
          </div>
          <div className="pix-payment-step3-content-table border-bottom-none">
            <div className="pix-receipt-table-mobile">
              <span className="pix-receipt-h1-mobile bold">Nome</span>
              <span className="pix-receipt-h1-mobile text-align-right">
                {pixPaymentStep1.nome}
              </span>
            </div>
            <div className="pix-receipt-table-mobile">
              <span className="pix-receipt-h1-mobile bold">Chave</span>
              <span className="pix-receipt-h1-mobile text-align-right">
                {pixPaymentStep1.chave}
              </span>
            </div>
            <div className="pix-receipt-table-mobile">
              <span className="pix-receipt-h1-mobile bold">CPF</span>
              <span className="pix-receipt-h1-mobile text-align-right">
                {pixPaymentStep1.cpf}
              </span>
            </div>
            <div className="pix-receipt-table-mobile">
              <span className="pix-receipt-h1-mobile bold">Instituição</span>
              <span className="pix-receipt-h1-mobile text-align-right">
                {pixPaymentStep1.numeroBanco}
              </span>
            </div>
            <div className="pix-receipt-table-mobile">
              <span className="pix-receipt-h1-mobile bold">Agência</span>
              <span className="pix-receipt-h1-mobile text-align-right">
                {pixPaymentStep1.agencia}
              </span>
            </div>
            <div className="pix-receipt-table-mobile">
              <span className="pix-receipt-h1-mobile bold">Conta</span>
              <span className="pix-receipt-h1-mobile text-align-right">
                {pixPaymentStep1.conta}
              </span>
            </div>
          </div>
          <div className="pix-receipt-img-title">
            <img
              className="pix-content-img"
              src={transfer}
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
            <span className="pix-content-txt">Origem</span>
          </div>
          <div className="pix-payment-step3-content-table border-bottom-none">
            <div className="pix-receipt-table-mobile">
              <span className="pix-receipt-h1-mobile bold">Nome</span>
              <span className="pix-receipt-h1-mobile text-align-right">
                {user?.nome}
              </span>
            </div>
            <div className="col"></div>
            <div className="pix-receipt-table-mobile">
              <span className="pix-receipt-h1-mobile bold">Instituição</span>
              <span className="pix-receipt-h1-mobile text-align-right">
                {current_account?.numeroBanco}
              </span>
            </div>
            <div className="pix-receipt-table-mobile">
              <span className="pix-receipt-h1-mobile bold">Agência</span>
              <span className="pix-receipt-h1-mobile text-align-right">
                {current_account?.agencia}
              </span>
            </div>
            <div className="pix-receipt-table-mobile">
              <span className="pix-receipt-h1-mobile bold">Conta</span>
              <span className="pix-receipt-h1-mobile text-align-right">
                {current_account?.conta}
              </span>
            </div>
          </div>
          <div className="pix-receipt-img-title mg-bottom-50">
            <div className="direction-column mg-bottom-50">
              <span className="pix-receipt-h1-mobile bold">
                ID da transação
              </span>
              <span className="pix-receipt-h1-mobile mg-top-8">
                {pixPaymentStep3.codigoTransacao}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PixReceiptMobile
