import "./styles.scss"
import SVGInject from "@iconfu/svg-inject"
import { useContext, useState } from "react"
import {
  PrimaryButton,
  FailureModal,
  CustomModal,
  SecondaryButton,
  SuccessModal,
} from "k4n-components"
import next from "../../../../../assets/images/next.svg"
import { AuthContext } from "../../../../../context/AuthProvider"
import dayjs, { BusinessHoursMap } from "dayjs"
import DatePicker from "react-datepicker"
import InputMask from "react-input-mask"
import { useForm } from "react-hook-form"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import share from "../../../../../assets/images/share_yellow.svg"
import entradaDinheiro from "../../../../../assets/images/entrada_dinheiro.svg"
import saidaDinheiro from "../../../../../assets/images/saida_dinheiro.svg"
import html2canvas from "html2canvas"
import { Link, useNavigate } from "react-router-dom"
import key from "../../../../../assets/images/key.svg"
import arrow from "../../../../../assets/images/arrow_yellow.svg"
import DigitalSignature from "../../../DigitalSignature/Form"
import services from "../../../../../services/svcmesh"
import { isDateNotEmpty } from "../../../../../utils/validation"

interface CamposBoleto {
  destinatario: string
  cpf_cnpj: string
  emissor: string
  dataCobranca: string
  dataVencimento: string
  dataLimPgto: string
  codigoBarras: string
  linhaDigitavel: string
  vlrTit: number
  vlrAbatimento: number
  vlrMulta: number
  vlrDesconto: number
  vlrTotal: number
  dtPgto?: string
  codigoTransacao?: string
  codigoMovimento?: string
  vlrPago?: number
  scheduledDate?: string
}

const businessTimes: BusinessHoursMap = {
  sunday: null,
  monday: [{ start: `07:00:00`, end: `22:00:00` }],
  tuesday: [{ start: `07:00:00`, end: `22:00:00` }],
  wednesday: [{ start: `07:00:00`, end: `22:00:00` }],
  thursday: [{ start: `07:00:00`, end: `22:00:00` }],
  friday: [{ start: `07:00:00`, end: `22:00:00` }],
  saturday: null,
}

dayjs.setBusinessTime(businessTimes)

const PaymentMobile = () => {
  const navigate = useNavigate()
  const { user, current_account, hasDigitalSignature } = useContext(AuthContext)
  const [digitalSignatureModal, setDigitalSignatureModal] = useState(false)
  const [authorizeModal, setAuthorizeModal] = useState(false)
  const [scheduledDate, setScheduledDate] = useState<Date | null>(null)
  const [barCode, setBarCode] = useState<string>(``)
  const [step, setStep] = useState<number>(1)
  const [callApiTrigger, setCallApiTrigger] = useState(0)
  const [modalAlert, setModalAlert] = useState(``)
  const [showPaymentDate, setShowPaymentDate] = useState(false)
  const [customModalAlert, setCustomModalAlert] = useState(``)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [isApplyFilterButtonLoading, setIsApplyFilterButtonLoading] =
    useState(false)
  const [info, setInfo] = useState<CamposBoleto>({
    destinatario: ``,
    cpf_cnpj: ``,
    emissor: ``,
    dataCobranca: ``,
    dataVencimento: ``,
    dataLimPgto: ``,
    linhaDigitavel: ``,
    codigoBarras: ``,
    vlrTit: 0,
    vlrAbatimento: 0,
    vlrMulta: 0,
    vlrDesconto: 0,
    vlrTotal: 0,
  })

  const barCodeInput = (e: any) => {
    setBarCode(e.target.value)
  }

  const showAlertModal = (message: string) => {
    setModalAlert(message)
  }

  const isWeekday = (date: Date) => {
    const day = dayjs(date).get(`d`)
    return day !== 0 && day !== 6
  }

  const { handleSubmit } = useForm()

  const authorize = () => {
    setAuthorizeModal(false)
    if (scheduledDate) {
      setIsApplyFilterButtonLoading(true)
      boletoScheduling(scheduledDate)
    } else {
      if (!dayjs().isBusinessTime()) {
        setCustomModalAlert(
          `Dado o dia e/ou horário, seu pagamento será agendado para o dia ${dayjs()
            .nextBusinessTime()
            .format(
              `DD/MM/YYYY`,
            )} dentro do intervalo de 07:00 às 22:00. Deseja continuar?`,
        )
        return
      }
      setIsApplyFilterButtonLoading(true)
      boletoPayment()
    }
  }

  const onSubmit = () => {
    setAuthorizeModal(true)
  }

  async function getBoletoInfo() {
    try {
      const response = await services.bankingService.getBoletoInfo({
        linhaDigitavel: barCode,
      })
      setInfo({
        destinatario: response?.dadosTitulo?.nomRzSocBenfcrioOr,
        cpf_cnpj: response?.dadosTitulo?.cnpjCpfBenfcrioOr,
        emissor: response?.dadosTitulo?.emissor,
        dataCobranca: response?.dadosTitulo?.dtHrSitTit,
        dataVencimento: response?.dadosTitulo?.dtVencTit,
        dataLimPgto: response?.dadosTitulo?.dtLimPgtoTit,
        codigoBarras: response?.dadosTitulo?.numCodBarras,
        linhaDigitavel: response?.dadosTitulo?.numLinhaDigtvl,
        vlrTit: response?.dadosTitulo?.vlrTit,
        vlrAbatimento: response?.dadosTitulo?.vlrAbattTit,
        vlrMulta: response?.multa?.total,
        vlrDesconto: response?.descontos[0]?.total,
        vlrTotal: response?.vlrTitTotal,
      })
      setIsApplyFilterButtonLoading(false)
      setStep(2)
    } catch (error: any) {
      if (error?.response?.status === 500) {
        showAlertModal(
          `Não foi possível consultar esse boleto. Verifique o código de barras e tente novamente.`,
        )
      } else {
        showAlertModal(
          error?.response?.data?.message || error?.message || error,
        )
      }
      setIsApplyFilterButtonLoading(false)
    }
  }

  async function boletoPayment() {
    try {
      const obj = {
        pagador: {
          agencia: current_account?.agencia || ``,
          agenciaDigito: current_account?.agencia || ``,
          conta: current_account?.conta || ``,
          contaDigito: current_account?.contaDigito || ``,
          contaPgto: String(current_account?.contaPgto || ``),
          tipoConta: Number(current_account?.tipoConta),
        },
        tipoProcessamento: 4,
        titulo: {
          linhaDigitavel: info.linhaDigitavel,
        },
        valorTitulo: info.vlrTit,
        dataVencimento: info.dataVencimento,
        valorPagamento: info.vlrTotal,
        dataPagamento: dayjs().format(),
        codOperacaoCli: ``,
        descCliente: ``,
      }
      const response = await services.bankingService.boletoPayment(obj)
      setIsApplyFilterButtonLoading(false)
      setInfo({
        ...info,
        dtPgto: dayjs().format(`DD/MM/YYYY - HH:mm:ss`),
        vlrPago: response?.data?.valorTransacao,
        codigoMovimento: response?.data?.codigoMovimento,
        codigoTransacao: response?.data?.codigoTransacao,
      })
      if (response?.data?.sucesso) {
        setShowSuccessModal(true)
      }
    } catch (error: any) {
      showAlertModal(error?.response?.data?.message || error?.message || error)
      setIsApplyFilterButtonLoading(false)
    }
  }

  async function boletoScheduling(date: Date) {
    try {
      const obj = {
        customer_pf: { id: Number(user?.id) },
        pagador_agencia: current_account?.agencia || ``,
        pagador_agencia_digito: current_account?.agenciaDigito || ``,
        pagador_conta: current_account?.conta || ``,
        pagador_conta_digito: current_account?.contaDigito || ``,
        pagador_conta_pgto: String(current_account?.contaPgto) || ``,
        pagador_tipo_conta: Number(current_account?.tipoConta),
        tipo_processamento: 4,
        titulo_linha_digitavel: info.linhaDigitavel,
        titulo_codigo_barras: info.codigoBarras,
        valor_titulo: info.vlrTit,
        data_vencimento: dayjs(info.dataVencimento).toDate(),
        valor_pagamento: info.vlrTotal,
        data_pagamento: info.dtPgto,
        scheduled_date: dayjs(date).format(`YYYY-MM-DD`),
        cod_operacao_cli: ``,
        desc_cliente: ``,
      }

      const response = await services.bankingService.boletoScheduling(obj)
      setIsApplyFilterButtonLoading(false)
      if (response.data.sucesso) {
        setInfo({
          ...info,
          scheduledDate: dayjs(date).format(`YYYY-MM-DD`),
          vlrPago: response?.data?.valorTransacao,
        })
        setCustomModalAlert(``)
        setShowSuccessModal(true)
      }
    } catch (error: any) {
      showAlertModal(error?.response?.data?.message || error?.message || error)
      setIsApplyFilterButtonLoading(false)
    }
  }

  const handleNext = () => {
    if (hasDigitalSignature) {
      setIsApplyFilterButtonLoading(true)
      getBoletoInfo()
    } else setDigitalSignatureModal(true)
  }

  const handleDownloadImage = async () => {
    html2canvas(document.querySelector(`#capture`)!).then((canvas) => {
      const data = canvas.toDataURL(`image/jpge`)
      const link = document.createElement(`a`)

      if (typeof link.download === `string`) {
        link.href = data
        link.download = `comprovante_boleto.png`

        link.click()
      } else {
        window.open(data)
      }
    })
  }

  return (
    <>
      <div className="payment-mobile-panel">
        <CustomModal
          isOpen={digitalSignatureModal}
          onClose={() => {
            setDigitalSignatureModal(false)
          }}
          isBanking={true}
        >
          <div style={{ width: `120px`, margin: `0 auto` }}>
            <img
              src={key}
              alt="/"
              width="120"
              height="120"
              className="key-vector"
              onLoad={(e) => SVGInject(e.target)}
            />
          </div>
          <h1 className="digital-signature-h1">
            Crie agora sua assinatura digital <br /> e aproveite todos os
            recursos de sua conta!
          </h1>
          <div className="flex-center mt-3">
            <Link style={{ textDecoration: `none` }} to="/digital-signature">
              <button className="home-bold-btn">Criar</button>
            </Link>
          </div>
        </CustomModal>
        <CustomModal
          isOpen={authorizeModal}
          onClose={() => {
            setAuthorizeModal(false)
          }}
          isBanking={true}
        >
          <div style={{ width: `120px`, margin: `0 auto` }}>
            <img
              src={key}
              alt="/"
              width="120"
              height="120"
              className="key-vector"
              onLoad={(e) => SVGInject(e.target)}
            />
          </div>
          <DigitalSignature handleSuccess={authorize} />
        </CustomModal>
        {step === 1 && (
          <>
            <div className="payment-header-mobile">
              <span
                onClick={() => {
                  navigate(`/banking`)
                }}
              >
                <img src={arrow} onLoad={(e) => SVGInject(e.target)}></img>
              </span>
              <label className="payment-mobile-title-h1">Pagamento</label>
              <label className="payment-mobile-title-h2">
                Digite ou cole a linha digitável do boleto que quer pagar
              </label>
            </div>
            <div className="payment-mobile-bar-code-container">
              <div className="payment-mobile-bar-code-div">
                <div className="payment-mobile-bar-code-box">
                  <input
                    className="payment-mobile-bar-code-input"
                    type="text"
                    placeholder="Digite a linha digitável"
                    onChange={barCodeInput}
                    minLength={44}
                    maxLength={48}
                  />
                </div>
              </div>
              <div className="payment-mobile-btn-div">
                <PrimaryButton
                  width={`95px`}
                  height={`70px`}
                  onClick={handleNext}
                >
                  {isApplyFilterButtonLoading ? (
                    <div className="payment-spinner"></div>
                  ) : (
                    <img src={next} alt="/" />
                  )}
                </PrimaryButton>
              </div>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <form
              className="payment-mobile-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <button
                className="payment-mobile-close-btn"
                onClick={() => {
                  setStep(1)
                }}
              ></button>
              <div className="payment-mobile-title">
                <label className="payment-mobile-title-h1">
                  Confira os dados do Boleto
                </label>
              </div>
              <div className="payment-mobile-info">
                {info.emissor && (
                  <div className="payment-mobile-row">
                    <span className="payment-mobile-span-title-black">
                      <b>Emissor:</b>
                    </span>
                    <span className="payment-mobile-span-content-grey">
                      {info.emissor}
                    </span>
                  </div>
                )}
              </div>
              <div className="payment-mobile-info">
                {info.destinatario && (
                  <div className="payment-mobile-row">
                    <span className="payment-mobile-span-title-black">
                      <b>Destinatário:</b>
                    </span>
                    <span className="payment-mobile-span-content-grey">
                      {info.destinatario}
                    </span>
                  </div>
                )}
                {info.cpf_cnpj && (
                  <div className="payment-mobile-row">
                    <span className="payment-mobile-span-title-black">
                      <b>CPF/CNPJ:</b>
                    </span>
                    <span className="payment-mobile-span-content-grey">
                      {info.cpf_cnpj}
                    </span>
                  </div>
                )}

                <div className="payment-mobile-row">
                  <span className="payment-mobile-span-title-black">
                    <b>Valor do boleto:</b>
                  </span>
                  <span className="payment-mobile-span-content-grey">
                    {info.vlrTit.toLocaleString(`pt-BR`, {
                      style: `currency`,
                      currency: `BRL`,
                    })}
                  </span>
                </div>
                {isDateNotEmpty(info.dataVencimento) && (
                  <div className="payment-mobile-row">
                    <span className="payment-mobile-span-title-black">
                      <b>Vencimento:</b>
                    </span>
                    <span className="payment-mobile-span-content-grey">
                      {dayjs(info.dataVencimento).format(`DD/MM/YYYY`)}
                    </span>
                  </div>
                )}
                <div className="payment-mobile-row">
                  <span className="payment-mobile-span-title-black">
                    <b>Agendar pagamento:</b>
                  </span>
                  <div className="form-check form-switch">
                    <input
                      className="payment-step2-date-input form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      onClick={() => {
                        setShowPaymentDate(!showPaymentDate)
                        setScheduledDate(null)
                      }}
                    />
                  </div>
                </div>
                {showPaymentDate === true && (
                  <DatePicker
                    selected={scheduledDate}
                    onChange={(date: any) => setScheduledDate(date)}
                    locale="pt-BR"
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    required={true}
                    minDate={dayjs().nextBusinessDay().toDate()}
                    maxDate={dayjs(info.dataLimPgto).toDate()}
                    filterDate={isWeekday}
                    placeholderText="Quando você quer pagar?"
                    customInput={
                      <InputMask
                        mask="99/99/9999"
                        maskPlaceholder={null}
                        className="payment-step2-message-input payment-step2-input-calender"
                      />
                    }
                  />
                )}
                <div className="payment-mobile-btn-div">
                  {showPaymentDate ? (
                    <PrimaryButton width={`100%`} height={`70px`}>
                      {isApplyFilterButtonLoading ? (
                        <div className="payment-spinner"></div>
                      ) : (
                        <span className="next-span">Agendar Pagamento</span>
                      )}
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton width={`100%`} height={`70px`}>
                      {isApplyFilterButtonLoading ? (
                        <div className="payment-spinner"></div>
                      ) : (
                        <span className="next-span">Pagar Boleto</span>
                      )}
                    </PrimaryButton>
                  )}
                </div>
              </div>
              {showSuccessModal && scheduledDate ? (
                <SuccessModal
                  isOpen={showSuccessModal}
                  width={`100%`}
                  height={`auto`}
                  onClose={() => {
                    setStep(1)
                    setShowSuccessModal(false)
                    setStep(1)
                    setScheduledDate(null)
                    setShowSuccessModal(false)
                    setShowPaymentDate(false)
                  }}
                >
                  <h1 className="pix-modal-sucess-h1">
                    Pagamento do boleto agendado com <br /> sucesso!
                  </h1>
                  <div className="boleto-justify-center">
                    <button
                      className="boleto-receipt-btn"
                      onClick={() => {
                        setCallApiTrigger(callApiTrigger + 1)
                        setStep(3)
                      }}
                    >
                      Ver comprovante
                    </button>
                  </div>
                </SuccessModal>
              ) : (
                <SuccessModal
                  isOpen={showSuccessModal}
                  width={`100%`}
                  height={`auto`}
                  onClose={() => {
                    setStep(1)
                    setShowSuccessModal(false)
                  }}
                >
                  <h1 className="pix-modal-sucess-h1">
                    Boleto pago com <br /> sucesso!
                  </h1>
                  <div className="boleto-justify-center">
                    <button
                      className="boleto-receipt-btn"
                      onClick={() => setStep(3)}
                    >
                      Ver comprovante
                    </button>
                  </div>
                </SuccessModal>
              )}
            </form>
          </>
        )}
        {step === 3 && (
          <>
            <button
              className="payment-mobile-close-btn"
              onClick={() => {
                setBarCode(``)
                setInfo({
                  destinatario: ``,
                  cpf_cnpj: ``,
                  emissor: ``,
                  dataCobranca: ``,
                  dataVencimento: ``,
                  dataLimPgto: ``,
                  linhaDigitavel: ``,
                  codigoBarras: ``,
                  vlrTit: 0,
                  vlrAbatimento: 0,
                  vlrMulta: 0,
                  vlrDesconto: 0,
                  vlrTotal: 0,
                })
                setStep(1)
                setScheduledDate(null)
                setShowSuccessModal(false)
                setShowPaymentDate(false)
              }}
            ></button>
            <button className="share-btn" onClick={handleDownloadImage}>
              <img src={share} alt="/" onLoad={(e) => SVGInject(e.target)} />
            </button>
            <div className="payment-mobile-margin-20" id="capture">
              <div className="receipt-capture-mobile">
                {info.dtPgto ? (
                  <>
                    <h1 className="payment-mobile-title-h1">
                      Comprovante de Pagamento
                    </h1>
                    <div className="payment-receipt">
                      <span className="row-span-title-18 margin-right-4">
                        Realizado em
                      </span>
                      <span className="row-span-title-18 w700">
                        {info.dtPgto}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <h1 className="payment-mobile-title-h1">
                      Comprovante de Agendamento
                    </h1>
                    <div className="payment-receipt">
                      <span className="row-span-title-18 margin-right-4">
                        Agendado para
                      </span>
                      <span className="row-span-title-18 w700">
                        {info.scheduledDate}
                      </span>
                    </div>
                  </>
                )}
                <div className="step3-section-mobile">
                  <h2 className="section-title">Valor do pagamento</h2>
                  <span className="section-2-span">
                    {info.vlrTotal.toLocaleString(`pt-BR`, {
                      style: `currency`,
                      currency: `BRL`,
                    })}
                  </span>
                </div>
                <div className="table-div">
                  <img
                    src={entradaDinheiro}
                    alt=""
                    onLoad={(e) => SVGInject(e.target)}
                  />
                  <span className="section-title padding-left-10">
                    Destinatário
                  </span>
                </div>
                <div className="payment-receipt-mobile-div">
                  <h2 className="row-title">Nome</h2>
                  <span className="row-span">{info.destinatario}</span>
                </div>
                <div className="payment-receipt-mobile-div">
                  <h2 className="row-title">CPF/CNPJ</h2>
                  <span className="row-span">{info.cpf_cnpj}</span>
                </div>
                <div className="table-div">
                  <img
                    src={saidaDinheiro}
                    alt=""
                    onLoad={(e) => SVGInject(e.target)}
                  />
                  <span className="section-title padding-left-10">Origem</span>
                </div>
                <div className="payment-receipt-mobile-div">
                  <h2 className="row-title">Nome</h2>
                  <span className="row-span">{user?.nome}</span>
                </div>
                <div className="payment-receipt-mobile-div">
                  <h2 className="row-title">Instituição</h2>
                  <span className="row-span">Kikkin</span>
                </div>
                <div className="payment-receipt-mobile-div">
                  <h2 className="row-title">Agencia</h2>
                  <span className="row-span">{current_account?.agencia}</span>
                </div>
                <div className="payment-receipt-mobile-div">
                  <h2 className="row-title">Conta</h2>
                  <span className="row-span">
                    {current_account?.conta +
                      `-` +
                      current_account?.contaDigito}
                  </span>
                </div>
                {info.codigoTransacao ? (
                  <div className="section margin-30">
                    <h2 className="section-title">Id da Transação</h2>
                    <span className="section-span">{info.codigoTransacao}</span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        )}
        <FailureModal
          isOpen={!!modalAlert}
          width={`360px`}
          height={`auto`}
          onClose={() => {
            setStep(1)
            setModalAlert(``)
            setScheduledDate(null)
            setIsApplyFilterButtonLoading(false)
            setShowPaymentDate(false)
          }}
        >
          <h1 className="pix-modal-sucess-h1">{modalAlert}</h1>
        </FailureModal>
        <CustomModal
          isOpen={!!customModalAlert}
          onClose={() => setCustomModalAlert(``)}
        >
          <div className="modal-message transfer-custom-modal">
            {customModalAlert}
          </div>
          <div className="transfer-schedule-alert">
            <SecondaryButton
              text="Não"
              width="200px"
              height="40px"
              OnClick={() => setCustomModalAlert(``)}
            />
            <PrimaryButton
              width="200px"
              height="40px"
              onClick={() => {
                setScheduledDate(dayjs().nextBusinessTime().toDate())
                boletoScheduling(dayjs().nextBusinessTime().toDate())
              }}
            >
              Sim
            </PrimaryButton>
          </div>
        </CustomModal>
      </div>
    </>
  )
}
export default PaymentMobile
