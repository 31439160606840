import "./styles.scss"
import "../styles.scss"
import pix from "../../../../../assets/images/pix.svg"
import transfer from "../../../../../assets/images/transfer.svg"
import copy from "../../../../../assets/images/copy_yellow.svg"
import qrcode from "../../../../../assets/images/others.svg"
import arrow_right from "../../../../../assets/images/arrow-right-yellow.svg"
import { useContext, useEffect, useState } from "react"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import { PixStorageContext } from "../../../../../context/PixStorageProvider"
import { AuthContext } from "../../../../../context/AuthProvider"
import SVGInject from "@iconfu/svg-inject"
import { useResposive } from "../../../../../hooks/useResponsive"
import PixPanelMobile from "./Mobile"
import { Link } from "react-router-dom"
import { CustomModal } from "k4n-components"
import key from "../../../../../assets/images/key.svg"
import services from "../../../../../services/svcmesh"
import { GetPixKeys, UserPixKeys } from "k4n-svcmesh-sdk"

const PixPanel = () => {
  const { changePixEnvironment, changePixPayment } = useContext(
    BankingNavigationContext,
  )
  const { isTabletOrMobile } = useResposive()
  const { addUserPixKeys } = useContext(PixStorageContext)
  const { current_account, hasDigitalSignature } = useContext(AuthContext)
  const [digitalSignatureModal, setDigitalSignatureModal] = useState(false)
  const getUserPixKeys = async () => {
    let accountLength = 0
    let contaPgt: string | undefined
    if (current_account?.conta && current_account?.contaDigito) {
      accountLength = (current_account?.conta + current_account?.contaDigito)
        .length
    }
    if (accountLength == 5)
      contaPgt = `00` + current_account?.conta + current_account?.contaDigito
    if (accountLength == 6)
      contaPgt = `0` + current_account?.conta + current_account?.contaDigito
    if (accountLength == 7)
      contaPgt = `` + current_account?.conta + current_account?.contaDigito

    const userAccount = {
      ContaDtoAgencia: current_account?.agencia,
      ContaDtoConta: current_account?.conta,
      ContaDtoContaDigito: current_account?.contaDigito,
      ContaDtoContaPgto: contaPgt,
      ContaDtoTipoConta: current_account?.tipoConta,
    } as GetPixKeys

    try {
      console.log(userAccount)
      const res = await services.bankingService.getPixKeys(userAccount)
      console.log(res)
      const userPixKeys = res.data.chaves
      const sendObj = {
        email: ``,
        evp: ``,
        celular: ``,
        cpf: ``,
      } as UserPixKeys
      userPixKeys.forEach((chaves: any) => {
        if (chaves.tipoChave === 0) {
          sendObj.cpf = chaves.chave
        }
        if (chaves.tipoChave === 2) {
          sendObj.celular = chaves.chave
        }
        if (chaves.tipoChave === 3) {
          sendObj.email = chaves.chave
        }
        if (chaves.tipoChave === 4) {
          sendObj.evp = chaves.chave
        }
      })
      addUserPixKeys(sendObj)
    } catch (error) {
      console.log(
        `🚀 ~ file: index.tsx ~ line 35 ~ getUserPixKeys ~ error`,
        error,
      )
    }
  }

  useEffect(() => {
    getUserPixKeys()
  }, [])

  const handlePageChange = (newPage: number) => {
    changePixPayment(1)
    changePixEnvironment(newPage)
  }

  const handlePayment = (newPage: number) => {
    if (hasDigitalSignature) handlePageChange(newPage)
    else setDigitalSignatureModal(true)
  }

  return (
    <>
      {isTabletOrMobile !== true ? (
        <div className="pix-panel-container">
          <CustomModal
            isOpen={digitalSignatureModal}
            onClose={() => {
              setDigitalSignatureModal(false)
            }}
            isBanking={true}
          >
            <div style={{ width: `120px`, margin: `0 auto` }}>
              <img
                src={key}
                alt="/"
                width="120"
                height="120"
                className="key-vector"
                onLoad={(e) => SVGInject(e.target)}
              />
            </div>
            <h1 className="digital-signature-h1">
              Crie agora sua assinatura digital <br /> e aproveite todos os
              recursos de sua conta!
            </h1>
            <div className="flex-center mt-3">
              <Link style={{ textDecoration: `none` }} to="/digital-signature">
                <button className="home-bold-btn">Criar</button>
              </Link>
            </div>
          </CustomModal>
          <div className="pix-panel">
            <img
              className="pix-content-img"
              src={pix}
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
            <h1 className="pix-panel-title">
              Receba e faça pagamentos sem pagar nada por isso.
            </h1>
          </div>
          <div className="pix-panel-content">
            <h1 className="pix-panel-content-title">Ambiente Pix</h1>
            <div
              className="pix-panel-content-table pix-payment-panel"
              onClick={() => handlePayment(2)}
            >
              <div className="align-center">
                <img
                  className="pix-content-img"
                  src={transfer}
                  alt="/"
                  onLoad={(e) => SVGInject(e.target)}
                />
                <span className="pix-content-txt">Pagar</span>
              </div>
              <img
                className="pix-content-img"
                src={arrow_right}
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </div>
            <div
              className="pix-panel-content-table"
              onClick={() => handlePageChange(3)}
            >
              <div className="align-center">
                <img
                  className="pix-content-img"
                  src={transfer}
                  alt="/"
                  onLoad={(e) => SVGInject(e.target)}
                />
                <span className="pix-content-txt">Receber</span>
              </div>
              <img
                className="pix-content-img"
                src={arrow_right}
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </div>
            <div
              className="pix-panel-content-table"
              onClick={() => handlePayment(4)}
            >
              <div className="align-center">
                <img
                  className="pix-content-img"
                  src={copy}
                  alt="/"
                  onLoad={(e) => SVGInject(e.target)}
                />
                <span className="pix-content-txt">Pix Copia e Cola</span>
              </div>
              <img
                className="pix-content-img"
                src={arrow_right}
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </div>
            <div
              className="pix-panel-content-table"
              onClick={() => handlePayment(5)}
            >
              <div className="align-center">
                <img
                  className="pix-content-img"
                  src={qrcode}
                  alt="/"
                  onLoad={(e) => SVGInject(e.target)}
                  width={24}
                  height={24}
                />
                <span className="pix-content-txt">Pagar com QR Code</span>
              </div>
              <img
                className="pix-content-img"
                src={arrow_right}
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </div>
            <h1 className="pix-panel-content-title">Configurar Pix</h1>
            <div
              className="pix-panel-content-table"
              onClick={() => handlePageChange(6)}
            >
              <div className="align-center">
                <span className="pix-content-txt">Minhas chaves</span>
                <span className="pix-tabel-txt">
                  Gerencie suas chaves para receber e fazer transferências pelo
                  Pix.
                </span>
              </div>
              <img
                className="pix-content-img"
                src={arrow_right}
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </div>
          </div>
        </div>
      ) : (
        <PixPanelMobile
          handlePageChange={handlePageChange}
          hasDigitalSignature={hasDigitalSignature}
        />
      )}
    </>
  )
}

export default PixPanel
