import "./styles.scss"
import pix from "../../../../../assets/images/navbar-banking/pix.svg"
import cell from "../../../../../assets/images/cell.svg"
import userImg from "../../../../../assets/images/user.svg"
import mail from "../../../../../assets/images/mail_yellow.svg"
import random_key from "../../../../../assets/images/random_key.svg"
import copy from "../../../../../assets/images/copy_green.svg"
import bin from "../../../../../assets/images/bin_green.svg"
import { useContext, useEffect, useState } from "react"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import {
  SecondaryButton,
  PrimaryButton,
  CustomModal,
  SuccessModal,
  FailureModal,
} from "k4n-components"
import {
  PixKeyType,
  PixStorageContext,
} from "../../../../../context/PixStorageProvider"
import { AxiosError } from "axios"
import { AuthContext } from "../../../../../context/AuthProvider"
import SVGInject from "@iconfu/svg-inject"
import { useResposive } from "../../../../../hooks/useResponsive"
import PixKeysMobile from "./Mobile"
import Timer from "../../../../../assets/images/navbar-banking/timer.svg"
import services from "../../../../../services/svcmesh"
import {
  Account,
  BmpConta,
  ConsultPixKey,
  CreatePixKey,
  DeletePixKey,
  GetPixKeys,
  UserPixKeys,
} from "k4n-svcmesh-sdk"

const PixKeys = () => {
  const { changePixEnvironment } = useContext(BankingNavigationContext)
  const { user, current_account } = useContext(AuthContext)
  const { userPixKeys, addUserPixKeys } = useContext(PixStorageContext)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loading, setLoading] = useState(true)
  const { isTabletOrMobile } = useResposive()
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalDeleteSucess, setOpenModalDeleteSucess] = useState(false)
  const [pixKeyType, setPixKeyType] = useState<PixKeyType>(5)
  const [showRegister, setShowRegister] = useState(false)
  const [errorRegister, setErrorRegister] = useState(String)
  const [btnLoading, setBtnLoading] = useState(false)
  const [showModalError, setShowModalError] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const getUserPixKeys = async () => {
    setLoading(true)
    let accountLength = 0
    let contaPgt: string | undefined
    if (current_account?.conta && current_account?.contaDigito) {
      accountLength = (current_account?.conta + current_account?.contaDigito)
        .length
    }
    if (accountLength == 5)
      contaPgt = `00` + current_account?.conta + current_account?.contaDigito
    if (accountLength == 6)
      contaPgt = `0` + current_account?.conta + current_account?.contaDigito
    if (accountLength == 7)
      contaPgt = `` + current_account?.conta + current_account?.contaDigito

    const userAccount = {
      ContaDtoAgencia: current_account?.agencia,
      ContaDtoAgenciaDigito: current_account?.agenciaDigito,
      ContaDtoConta: current_account?.conta,
      ContaDtoContaDigito: current_account?.contaDigito,
      ContaDtoContaPgto: contaPgt,
      ContaDtoTipoConta: current_account?.tipoConta,
    } as GetPixKeys
    try {
      const res = await services.bankingService.getPixKeys(userAccount)
      const userPixKeys = res.data.chaves
      const sendObj = `` as UserPixKeys
      userPixKeys.forEach((chaves: any) => {
        if (chaves.tipoChave === PixKeyType.CPF) {
          sendObj.cpf = chaves.chave
        }
        if (chaves.tipoChave === PixKeyType.CELULAR) {
          sendObj.celular = chaves.chave
        }
        if (chaves.tipoChave === PixKeyType.EMAIL) {
          sendObj.email = chaves.chave
        }
        if (chaves.tipoChave === PixKeyType.EVP) {
          sendObj.evp = chaves.chave
        }
      })
      addUserPixKeys(sendObj)
      setLoading(false)
    } catch (error: any) {
      const err = error as AxiosError<any>
      console.log(err.response?.data.message)
      setLoading(false)
    }
  }

  const handleDeletePixKey = async () => {
    setLoadingDelete(true)
    let pixKey: string | undefined
    if (pixKeyType === PixKeyType.CPF) pixKey = userPixKeys.cpf
    if (pixKeyType === PixKeyType.CELULAR) pixKey = userPixKeys.celular
    if (pixKeyType === PixKeyType.EMAIL) pixKey = userPixKeys.email
    if (pixKeyType === PixKeyType.EVP) pixKey = userPixKeys.evp

    let accountLength = 0
    let contaPgt: string | undefined
    if (current_account?.conta && current_account?.contaDigito) {
      accountLength = (current_account?.conta + current_account?.contaDigito)
        .length
    }
    if (accountLength == 5)
      contaPgt = `00` + current_account?.conta + current_account?.contaDigito
    if (accountLength == 6)
      contaPgt = `0` + current_account?.conta + current_account?.contaDigito
    if (accountLength == 7)
      contaPgt = `` + current_account?.conta + current_account?.contaDigito

    const conta = {
      agencia: current_account?.agencia,
      agenciaDigito: current_account?.agenciaDigito,
      conta: current_account?.conta,
      contaDigito: current_account?.contaDigito,
      contaPgto: contaPgt,
      tipoConta: current_account?.tipoConta,
    } as BmpConta

    const sendObj = {
      conta: conta,
      chave: pixKey,
    } as DeletePixKey

    try {
      const res = await services.bankingService.deletePixKey(sendObj)
      if (res.data.sucesso === true) {
        getUserPixKeys()
        setLoadingDelete(false)
        setOpenModalDeleteSucess(true)
      }
    } catch (error) {
      setLoadingDelete(false)
    }
  }

  const copyPixKey = (key: string) => {
    navigator.clipboard.writeText(key)
  }

  useEffect(function () {
    getUserPixKeys()
  }, [])

  const defaultValuesPix = {
    phone: `+55` + user?.phones[0].phone,
    email: user?.emails[0].email,
    cpf: user?.cpf,
    cnpj: ``,
    random_key: ``,
  }

  const consultPix = async (chave: any) => {
    if (pixKeyType !== 4) {
      try {
        let accountLength = 0
        let contaPgt: string | undefined
        if (current_account?.conta && current_account?.contaDigito) {
          accountLength = (
            current_account?.conta + current_account?.contaDigito
          ).length
        }
        if (accountLength == 5)
          contaPgt =
            `00` + current_account?.conta + current_account?.contaDigito
        if (accountLength == 6)
          contaPgt = `0` + current_account?.conta + current_account?.contaDigito
        if (accountLength == 7)
          contaPgt = `` + current_account?.conta + current_account?.contaDigito

        const sendObj = {
          Chave: chave,
          ContaDtoAgencia: current_account?.agencia,
          ContaDtoAgenciaDigito: current_account?.agenciaDigito,
          ContaDtoConta: current_account?.conta,
          ContaDtoContaDigito: current_account?.contaDigito,
          ContaDtoContaPgto: contaPgt,
          ContaDtoTipoConta: current_account?.tipoConta,
        } as ConsultPixKey
        const res = await services.bankingService.consultPixKey(sendObj)
        console.log(res.data)
        if (res.data.sucesso === true) {
          return `Cadastrada`
        }
      } catch (error: any) {
        const err = error as AxiosError<any>
        if (err.response?.data.message === `A chave PIX não foi encontrada.`) {
          return `Nao cadastrada`
        } else {
          setBtnLoading(false)
          setErrorRegister(err.response?.data.message)
          setShowModalError(true)
        }
      }
    } else return `Nao cadastrada`
  }

  const handleCreatePixKey = async (key: string) => {
    setBtnLoading(true)
    try {
      const resConsult = await consultPix(key)
      console.log(resConsult)
      if (resConsult === `Nao cadastrada`) {
        const account = {
          agencia: current_account?.agencia,
          agenciaDigito: current_account?.agencia,
          conta: current_account?.conta,
          contaDigito: current_account?.contaDigito,
          contaPgto: current_account?.contaPgto,
          tipoConta: current_account?.tipoConta,
        } as Account
        const sendObj = {
          contaDto: account,
          tipoChave: null,
          chave: key,
        } as unknown as CreatePixKey
        if (pixKeyType === PixKeyType.CPF) {
          sendObj.tipoChave = 0
          sendObj.chave = ``
        }
        if (pixKeyType === PixKeyType.CELULAR) sendObj.tipoChave = 2
        if (pixKeyType === PixKeyType.EMAIL) sendObj.tipoChave = 3
        if (pixKeyType === PixKeyType.EVP) sendObj.tipoChave = 4
        console.log(sendObj)
        const res = await services.bankingService.createPixKey(sendObj)
        console.log(res)
        if (res.data.sucesso === true) {
          setBtnLoading(false)
          setShowModal(true)
        }
      }
      if (resConsult === `Cadastrada`) {
        setErrorRegister(`Essa chave PIX já foi cadastrada!`)
        setBtnLoading(false)
        setShowModalError(true)
      }
    } catch (error: any) {
      const err = error as AxiosError<any>
      setBtnLoading(false)
      setErrorRegister(err.response?.data.message)
      setShowModalError(true)
    }
  }

  const handlePageChange = (newPage: number) => {
    changePixEnvironment(newPage)
  }

  return (
    <>
      {!isTabletOrMobile ? (
        <div>
          {!showRegister ? (
            <div className="pix-keys-container">
              <div className="pix-keys">
                <img
                  className="pix-content-img"
                  src={pix}
                  alt="/"
                  onLoad={(e) => SVGInject(e.target)}
                />
                <h1 className="pix-keys-title">
                  Gerencie suas chaves para receber e fazer transferências pelo
                  Pix.
                </h1>
              </div>
              <div className="pix-keys-content">
                <h1 className="pix-keys-content-title">Minhas chaves</h1>
                <div className="pix-keys-space-between">
                  <span className="pix-keys-title">
                    Com sua chave você pode <b>transferir e receber</b> de
                    qualquer conta,
                    {` `}
                    <b>independente de banco e horário.</b>
                  </span>
                </div>
                {loading ? (
                  <>
                    <div className="content-table line-top">
                      <span className="grey-box gradient" />
                      <span className="grey-box gradient" />
                      <span className="grey-box gradient" />
                    </div>
                    <div className="content-table">
                      <span className="grey-box gradient" />
                      <span className="grey-box gradient" />
                      <span className="grey-box gradient" />
                    </div>
                    <div className="content-table">
                      <span className="grey-box gradient" />
                      <span className="grey-box gradient" />
                      <span className="grey-box gradient" />
                    </div>
                    <div className="content-table">
                      <span className="grey-box gradient" />
                      <span className="grey-box gradient" />
                      <span className="grey-box gradient" />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="content-table line-top">
                      <div className="align-center">
                        <span>
                          <img
                            className="pix-content-img"
                            src={cell}
                            alt="/"
                            onLoad={(e) => SVGInject(e.target)}
                          />
                        </span>
                        <span className="pix-content-txt">Celular</span>
                      </div>
                      {!userPixKeys.celular ? (
                        <span
                          className="add-key-container"
                          onClick={() => {
                            setPixKeyType(PixKeyType.CELULAR)
                            setShowRegister(true)
                          }}
                        >
                          +
                        </span>
                      ) : (
                        <>
                          <span className="pix-keys-title">
                            {userPixKeys.celular}
                          </span>
                          <div className="align-center">
                            <span
                              onClick={() => copyPixKey(userPixKeys.celular!)}
                            >
                              <img
                                className="pix-content-img mg-left-35 pointer"
                                src={copy}
                                alt="/"
                                onLoad={(e) => SVGInject(e.target)}
                              />
                            </span>
                            <span
                              onClick={() => {
                                setPixKeyType(PixKeyType.CELULAR)
                                setOpenModalDelete(true)
                              }}
                            >
                              <img
                                className="pix-content-img mg-left-35 pointer"
                                src={bin}
                                alt="/"
                                onLoad={(e) => SVGInject(e.target)}
                              />
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="content-table">
                      <div className="align-center">
                        <span>
                          <img
                            className="pix-content-img"
                            src={userImg}
                            alt="/"
                            onLoad={(e) => SVGInject(e.target)}
                          />
                        </span>
                        <span className="pix-content-txt">CPF ou CNPJ</span>
                      </div>

                      {!userPixKeys.cpf ? (
                        <span
                          className="add-key-container"
                          onClick={() => {
                            setPixKeyType(PixKeyType.CPF)
                            setShowRegister(true)
                          }}
                        >
                          +
                        </span>
                      ) : (
                        <>
                          <span className="pix-keys-title">
                            {userPixKeys.cpf}
                          </span>
                          <div className="align-center">
                            <span onClick={() => copyPixKey(userPixKeys.cpf!)}>
                              <img
                                className="pix-content-img mg-left-35 pointer"
                                src={copy}
                                alt="/"
                                onLoad={(e) => SVGInject(e.target)}
                              />
                            </span>
                            <span
                              onClick={() => {
                                setPixKeyType(PixKeyType.CPF)
                                setOpenModalDelete(true)
                              }}
                            >
                              <img
                                className="pix-content-img mg-left-35 pointer"
                                src={bin}
                                alt="/"
                                onLoad={(e) => SVGInject(e.target)}
                              />
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="content-table">
                      <div className="align-center">
                        <span>
                          <img
                            className="pix-content-img"
                            src={mail}
                            alt="/"
                            onLoad={(e) => SVGInject(e.target)}
                          />
                        </span>
                        <span className="pix-content-txt">E-mail</span>
                      </div>

                      {!userPixKeys.email ? (
                        <span
                          className="add-key-container"
                          onClick={() => {
                            setPixKeyType(PixKeyType.EMAIL)
                            setShowRegister(true)
                          }}
                        >
                          +
                        </span>
                      ) : (
                        <>
                          <span className="pix-keys-title">
                            {userPixKeys.email}
                          </span>
                          <div className="align-center">
                            <span
                              onClick={() => copyPixKey(userPixKeys.email!)}
                            >
                              <img
                                className="pix-content-img mg-left-35 pointer"
                                src={copy}
                                alt="/"
                                onLoad={(e) => SVGInject(e.target)}
                              />
                            </span>
                            <span
                              onClick={() => {
                                setPixKeyType(PixKeyType.EMAIL)
                                setOpenModalDelete(true)
                              }}
                            >
                              <img
                                className="pix-content-img mg-left-35 pointer"
                                src={bin}
                                alt="/"
                                onLoad={(e) => SVGInject(e.target)}
                              />
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="content-table">
                      <div className="align-center">
                        <span>
                          <img
                            className="pix-content-img"
                            src={random_key}
                            alt="/"
                            onLoad={(e) => SVGInject(e.target)}
                          />
                        </span>
                        <span className="pix-content-txt">Chave aleatória</span>
                      </div>
                      {!userPixKeys.evp ? (
                        <span
                          className="add-key-container"
                          onClick={() => {
                            setPixKeyType(PixKeyType.EVP)
                            setShowRegister(true)
                          }}
                        >
                          +
                        </span>
                      ) : (
                        <>
                          <span className="pix-keys-title">
                            {userPixKeys.evp}
                          </span>
                          <div className="align-center">
                            <span onClick={() => copyPixKey(userPixKeys.evp!)}>
                              <img
                                className="pix-content-img mg-left-35 pointer"
                                src={copy}
                                alt="/"
                                onLoad={(e) => SVGInject(e.target)}
                              />
                            </span>
                            <span
                              onClick={() => {
                                setPixKeyType(PixKeyType.EVP)
                                setOpenModalDelete(true)
                              }}
                            >
                              <img
                                className="pix-content-img mg-left-35 pointer"
                                src={bin}
                                alt="/"
                                onLoad={(e) => SVGInject(e.target)}
                              />
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
                <div className="pix-align-btn">
                  <SecondaryButton
                    width={`170px`}
                    height={`60px`}
                    text={`Voltar`}
                    margin_right={``}
                    OnClick={() => handlePageChange(1)}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="pix-register-show-content">
              {pixKeyType === 2 && (
                <>
                  <h1 className="pix-register-content-title">
                    Registrar celular
                  </h1>
                  <h1 className="pix-register-show-title">
                    Confirme o número do celular que você quer cadastrar para
                    usar o Pix.
                  </h1>
                  <div className="pix-register-key">
                    {userPixKeys.celular ? (
                      <span className="pix-register-span">Ativa</span>
                    ) : (
                      <span className="pix-register-span pix-register-span-disabled">
                        Desativada
                      </span>
                    )}
                    <span className="pix-register-check-txt">
                      {defaultValuesPix.phone}
                    </span>
                  </div>
                  <div className="pix-align-btn">
                    <SecondaryButton
                      width={`170px`}
                      height={`60px`}
                      text={`Voltar`}
                      margin_right={`50px`}
                      OnClick={() => setShowRegister(false)}
                    />
                    {!userPixKeys.celular && (
                      <PrimaryButton
                        width={`312px`}
                        height={`60px`}
                        onClick={() =>
                          handleCreatePixKey(defaultValuesPix.phone!)
                        }
                      >
                        {!btnLoading ? (
                          `Registrar chave`
                        ) : (
                          <div
                            className="spinner-border spinner-pix"
                            role="status"
                          ></div>
                        )}
                      </PrimaryButton>
                    )}
                  </div>
                </>
              )}
              {/* {pixKeyType === 0 && (
              <>
                <h1 className="pix-register-content-title">Registrar CNPJ</h1>
                <h1 className="pix-register-show-title">
                  Confirme o CNPJ que você quer utilizar para usar o Pix.
                </h1>
                <div className="pix-register-check form-check form-switch">
                  <input
                    className="pix-register-check-input form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                  />
                  <span className="pix-register-check-txt">
                    {defaultValuesPix.cpf}
                  </span>
                </div>
              </>
            )} */}
              {pixKeyType === 0 && (
                <>
                  <h1 className="pix-register-content-title">Registrar CPF</h1>
                  <h1 className="pix-register-show-title">
                    Confirme o CPF que você quer cadastrar para usar o Pix.
                  </h1>
                  <div className="pix-register-key">
                    {userPixKeys.cpf ? (
                      <span className="pix-register-span">Ativa</span>
                    ) : (
                      <span className="pix-register-span pix-register-span-disabled">
                        Desativada
                      </span>
                    )}
                    <span className="pix-register-check-txt">
                      {defaultValuesPix.cpf}
                    </span>
                  </div>
                  <div className="pix-align-btn">
                    <SecondaryButton
                      width={`170px`}
                      height={`60px`}
                      text={`Voltar`}
                      margin_right={`50px`}
                      OnClick={() => setShowRegister(false)}
                    />
                    {!userPixKeys.cpf && (
                      <PrimaryButton
                        width={`312px`}
                        height={`60px`}
                        onClick={() =>
                          handleCreatePixKey(defaultValuesPix.cpf!)
                        }
                      >
                        {!btnLoading ? (
                          `Registrar chave`
                        ) : (
                          <div
                            className="spinner-border spinner-pix"
                            role="status"
                          ></div>
                        )}
                      </PrimaryButton>
                    )}
                  </div>
                </>
              )}
              {pixKeyType === 3 && (
                <>
                  <h1 className="pix-register-content-title">
                    Registrar E-mail
                  </h1>
                  <h1 className="pix-register-show-title">
                    Confirme o e-mail que você quer cadastrar para usar o Pix.
                  </h1>
                  <div className="pix-register-key">
                    {userPixKeys.email ? (
                      <span className="pix-register-span">Ativa</span>
                    ) : (
                      <span className="pix-register-span pix-register-span-disabled">
                        Desativada
                      </span>
                    )}
                    <span className="pix-register-check-txt">
                      {defaultValuesPix.email}
                    </span>
                  </div>
                  <div className="pix-align-btn">
                    <SecondaryButton
                      width={`170px`}
                      height={`60px`}
                      text={`Voltar`}
                      margin_right={`50px`}
                      OnClick={() => setShowRegister(false)}
                    />
                    {!userPixKeys.email && (
                      <PrimaryButton
                        width={`312px`}
                        height={`60px`}
                        onClick={() =>
                          handleCreatePixKey(defaultValuesPix.email!)
                        }
                      >
                        {!btnLoading ? (
                          `Registrar chave`
                        ) : (
                          <div
                            className="spinner-border spinner-pix"
                            role="status"
                          ></div>
                        )}
                      </PrimaryButton>
                    )}
                  </div>
                </>
              )}
              {pixKeyType === 4 && (
                <>
                  <h1 className="pix-register-content-title">
                    Registrar chave aleatória
                  </h1>
                  <h1 className="pix-register-show-title">
                    Com a chave aleatória você consegue receber por QR Code sem
                    compartilhar seus dados.
                  </h1>
                  <div className="pix-register-key">
                    {userPixKeys.evp ? (
                      <span className="pix-register-span">Ativa</span>
                    ) : (
                      <span className="pix-register-span pix-register-span-disabled">
                        Desativada
                      </span>
                    )}
                    <span className="pix-register-check-txt">
                      {userPixKeys.evp}
                    </span>
                  </div>
                  <div className="pix-align-btn">
                    <SecondaryButton
                      width={`170px`}
                      height={`60px`}
                      text={`Voltar`}
                      margin_right={`50px`}
                      OnClick={() => setShowRegister(false)}
                    />
                    {!userPixKeys.evp && (
                      <PrimaryButton
                        width={`312px`}
                        height={`60px`}
                        onClick={() =>
                          handleCreatePixKey(defaultValuesPix.random_key)
                        }
                      >
                        {!btnLoading ? (
                          `Registrar chave`
                        ) : (
                          <div
                            className="spinner-border spinner-pix"
                            role="status"
                          ></div>
                        )}
                      </PrimaryButton>
                    )}
                  </div>
                </>
              )}
              <SuccessModal
                isOpen={showModal}
                width={`937px`}
                height={`576px`}
                onClose={() => handlePageChange(1)}
              >
                <h1 className="pix-modal-sucess-h1">
                  Chave gerada <br /> com sucesso!
                </h1>
                <h1 className="pix-modal-sucess-3p">• • •</h1>
                <h1 className="pix-modal-sucess-txt">
                  Tudo certo! Em alguns minutos você pode começar <br /> a
                  receber Pix usando sua chave.
                </h1>
              </SuccessModal>

              <FailureModal
                isOpen={showModalError}
                width={`937px`}
                height={`576px`}
                onClose={() => setShowModalError(false)}
              >
                <h1 className="pix-modal-sucess-h1">
                  Ocorreu um erro ao registrar a chave!
                </h1>
                <h1 className="pix-modal-sucess-3p">• • •</h1>
                <h1 className="pix-modal-sucess-txt">{errorRegister}</h1>
                <div className="pix-align-center">
                  <PrimaryButton
                    width={`312px`}
                    height={`60px`}
                    onClick={() => setShowModalError(false)}
                  >
                    OK!
                  </PrimaryButton>
                </div>
              </FailureModal>
            </div>
          )}
        </div>
      ) : (
        <PixKeysMobile
          copyPixKey={copyPixKey}
          setPixKeyType={setPixKeyType}
          setOpenModalDelete={setOpenModalDelete}
        />
      )}
      <CustomModal
        isOpen={openModalDelete}
        onClose={() => setOpenModalDelete(false)}
        modalBottom={isTabletOrMobile ? true : false}
        containerBg={`#ffffff`}
      >
        <>
          <div
            className={
              isTabletOrMobile
                ? `pix-keys-modal-mobile`
                : `pix-keys-delete-modal`
            }
          >
            {!openModalDeleteSucess ? (
              <>
                <h1 className="pix-keys-content-title mg-bottom-24">
                  Você quer mesmo excluir essa chave?
                </h1>
                <div className="inline-flex">
                  <SecondaryButton
                    width={`120px`}
                    height={`60px`}
                    text={`Não`}
                    margin_right={`20px`}
                    OnClick={() => setOpenModalDelete(false)}
                  />
                  <PrimaryButton
                    width={`140px`}
                    height={`60px`}
                    onClick={() => handleDeletePixKey()}
                  >
                    {!loadingDelete ? (
                      `Sim, excluir`
                    ) : (
                      <div
                        className="spinner-border spinner-pix-keys-delete"
                        role="status"
                      />
                    )}
                  </PrimaryButton>
                </div>
              </>
            ) : (
              <>
                <img
                  src={Timer}
                  width="60px"
                  onLoad={(e) => SVGInject(e.target)}
                />
                <h1 className="pix-receive-modal-sucess-h1">
                  Exclusão de chave solicitada!
                </h1>
                <h1 className="pix-keys-title">
                  Pode levar alguns minutos até que sua chave seja excluída.
                </h1>
              </>
            )}
          </div>
        </>
      </CustomModal>
    </>
  )
}
export default PixKeys
