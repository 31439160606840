import {
  createContext,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react"
import k4n_logo from "../assets/images/kikkin_white_logo.svg"
import LoadingOverlay from "../components/features/LoadingOverlay"
import services from "../services/svcmesh"
import { PartnerCustomTheme, ThemeDto } from "../typings/custom-theme.dto"

type ThemeContextType = {
  logo: string
  theme: PartnerCustomTheme
  logoutRedirectUri: string
}

const themeContextDefaultValues = {
  logo: k4n_logo,
  theme: {
    primary_color: `#005476`,
    secondary_color: `#fbb040`,
    tertiary_color: ``,
    quaternary_color: ``,
    quinary_color: ``,
    senary_color: ``,
    onboarding_primary_color: ``,
    onboarding_primary_light_color: ``,
    primary_light_color: ``,
    secondary_light_color: ``,
    headers: {
      fav_icon: ``,
      title: ``,
      fonts: {
        regular: ``,
        bold: ``,
      },
    },
    images: {
      login_background_image: ``,
      onboarding_background_image_initial: ``,
      onboarding_background_image_middle_1: ``,
      onboarding_background_image_middle_2: ``,
      onboarding_background_image_middle_3: ``,
      onboarding_background_image_final: ``,
    },
  },
  logoutRedirectUri: `/`,
}

export const ThemeContext = createContext<ThemeContextType>(
  themeContextDefaultValues,
)

const ThemeProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [loading, setLoading] = useState(true)
  const [logo, setLogo] = useState(themeContextDefaultValues.logo)
  const [theme, setTheme] = useState(themeContextDefaultValues.theme)
  const [logoutRedirectUri, setLogoutRedirectUri] = useState(
    themeContextDefaultValues.logoutRedirectUri,
  )

  // check if need to be refetched or not
  useEffect(() => {
    setLoading(true)
    const hasStored = getFromLocalStorage()
    if (!hasStored) getCustomTheme()
  }, [])

  const getCustomTheme = async () => {
    try {
      const res = await services.themeService.getPartnerTheme()
      const { logo, custom_theme, logout_redirect_uri } = res.data as ThemeDto

      if (logo) {
        setLogo(logo)
        sessionStorage.setItem(`logo`, logo)
      }

      if (logout_redirect_uri) {
        setLogoutRedirectUri(logout_redirect_uri)
        sessionStorage.setItem(`logout_redirect_uri`, logout_redirect_uri)
      }

      if (custom_theme) {
        sessionStorage.setItem(`custom_theme`, JSON.stringify(custom_theme))
        setCssVariables(custom_theme)
      }
    } catch (error) {
      console.log(`Theme Error => ` + error)
    }
    setLoading(false)
  }

  const getFromLocalStorage = () => {
    const custom_theme = sessionStorage.getItem(`custom_theme`)
    if (custom_theme) {
      const theme = JSON.parse(custom_theme)
      setCssVariables(theme)
    }

    const logo = sessionStorage.getItem(`logo`)
    if (logo) setLogo(logo)

    const logout_redirect_uri = sessionStorage.getItem(`logout_redirect_uri`)
    if (logout_redirect_uri) setLogoutRedirectUri(logout_redirect_uri)

    if (custom_theme && logo && logout_redirect_uri) {
      setLoading(false)
      return true
    }

    return false
  }

  const setCssVariables = (partner_theme: any = {}) => {
    setTheme(partner_theme)
    const r = document.querySelector(`:root`) as any

    if (partner_theme.primary_color)
      r?.style.setProperty(`--k4n-primary-color`, partner_theme.primary_color)

    if (partner_theme.primary_light_color)
      r?.style.setProperty(
        `--k4n-primary-light-color`,
        partner_theme.primary_light_color,
      )

    if (partner_theme.secondary_color)
      r?.style.setProperty(
        `--k4n-secondary-color`,
        partner_theme.secondary_color,
      )

    if (partner_theme.secondary_light_color)
      r?.style.setProperty(
        `--k4n-secondary-light-color`,
        partner_theme.secondary_light_color,
      )

    if (partner_theme.tertiary_color)
      r?.style.setProperty(`--k4n-tertiary-color`, partner_theme.tertiary_color)

    if (partner_theme.quaternary_color)
      r?.style.setProperty(
        `--k4n-quaternary-color`,
        partner_theme.quaternary_color,
      )

    if (partner_theme.quinary_color)
      r?.style.setProperty(`--k4n-quinary-color`, partner_theme.quinary_color)

    if (partner_theme.senary_color)
      r?.style.setProperty(`--k4n-senary-color`, partner_theme.senary_color)
  }

  return (
    <ThemeContext.Provider
      value={{
        logo,
        theme,
        logoutRedirectUri,
      }}
    >
      {loading ? <LoadingOverlay noTheme={true} /> : children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
